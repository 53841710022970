import {
    GET_NOTIFICATIONS_SUCCESS,
    HIDE_MESSAGE,
    SHOW_MESSAGE, TOGGLE_DROPDOWN_NOTIFICATION,
} from "constants/ActionTypes";

const INIT_STATE = {
    alertMessage: '',
    showMessage: false,
    showDropDownNotification: false,
    notifications: {},
    notificationCount: 0,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload,
                notificationCount: Object.values(action.payload).map(value => value.count).reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue;
                })
            }
        case TOGGLE_DROPDOWN_NOTIFICATION:
            return {
                ...state,
                showDropDownNotification: !state['showDropDownNotification']
            }
        default:
            return state;
    }
}
