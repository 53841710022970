import {
    GET_PROPERTY_IMAGES,
    GET_PROPERTY_IMAGES_SUCCESS,
    UPLOAD_PROPERTY_IMAGES,
    UPLOAD_PROPERTY_IMAGES_SUCCESS,
    DELETE_PROPERTY_IMAGE,
    DELETE_PROPERTY_IMAGE_SUCCESS,
    CANCEL_IMAGE_ACTION,
    OPEN_IMAGE_UPLOAD_DIALOG,
    OPEN_IMAGE_DELETE_DIALOG,
} from "../../constants/ActionTypes";

export const getPropertyImages = ({id}) => {
    return {
        type: GET_PROPERTY_IMAGES,
        payload: {id}
    }
};

export const getPropertyImagesSuccess = (payload) => {
    return {
        type: GET_PROPERTY_IMAGES_SUCCESS,
        payload: payload
    }
};

export const uploadPropertyImages = ({data, id}) => {
    return {
        type: UPLOAD_PROPERTY_IMAGES,
        payload: {data, id}
    }
};

export const uploadPropertyImagesSuccess = (payload) => {
    return {
        type: UPLOAD_PROPERTY_IMAGES_SUCCESS,
        payload: payload
    }
};
export const cancelImageAction = () => {
    return {
        type: CANCEL_IMAGE_ACTION
    }
};

export const deletePropertyImage = (payload) => {
    return {
        type: DELETE_PROPERTY_IMAGE,
        payload: payload
    }
};

export const deletePropertyImageSuccess = (payload) => {
    return {
        type: DELETE_PROPERTY_IMAGE_SUCCESS,
        payload: payload
    }
};

export const openDeleteImageDialog = (payload) => {
    return {
        type: OPEN_IMAGE_DELETE_DIALOG,
        payload: payload
    }
};

export const openUploadImageDialog = (payload) => {
    return {
        type: OPEN_IMAGE_UPLOAD_DIALOG,
        payload: payload
    }
};




