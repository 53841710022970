import React from "react";

export const EMPLOYEE_STATUS_DICT = {
    ACTIVE: true,
    LOCKED: false
}

export const EMPLOYEE_DISPLAY_STATUS_DICT = {
    [EMPLOYEE_STATUS_DICT.ACTIVE]: 'Hoạt động',
    [EMPLOYEE_STATUS_DICT.LOCKED]: 'Đã khóa',
}



export const EMPLOYEE_STYLE_STATUS_DICT = {
    [EMPLOYEE_STATUS_DICT.ACTIVE]: 'bg-info text-white',
    [EMPLOYEE_STATUS_DICT.LOCKED]: 'bg-secondary text-white',
}


export const GROUP_DICT = {
    ADMIN: 1,
    VIEW_PROPERTY: 2,
    UPDATE_PROPERTY: 3,
    CONFIRM_PROPERTY: 4,
    VIEW_CUSTOMER: 5,
    UPDATE_CUSTOMER: 6,
    VIEW_EMPLOYEE: 7,
    UPDATE_EMPLOYEE: 8,
    VIEW_TRANSACTION: 9,
    VIEW_TRANSACTION_ORDER: 10,
    UPDATE_TRANSACTION_ORDER: 11,
    VIEW_BANK_ACCOUNT: 12,
    UPDATE_BANK_ACCOUNT: 13,
    VIEW_OFFER: 14,
    VIEW_TRADE: 15,
    VIEW_PAYMENT_ORDER: 16,
    UPDATE_PAYMENT_ORDER: 17,
    CONFIRM_PAYMENT_ORDER: 18,
    VIEW_POLL: 19,
    UPDATE_POLL: 20,
    UPDATE_EMPLOYEE_TRANSACTION_ORDER: 21,
    CREATE_TRANSACTION_ORDER: 22,
}

export const GROUP_DISPLAY_DICT = {
    [GROUP_DICT.ADMIN]: "Quản trị hệ thống",
    [GROUP_DICT.VIEW_PROPERTY]: "Xem sản phẩm đầu tư",
    [GROUP_DICT.UPDATE_PROPERTY]: "Tạo cập nhật sản phẩm đầu tư",
    [GROUP_DICT.CONFIRM_PROPERTY]: "Duyệt sản phẩm đầu tư",
    [GROUP_DICT.VIEW_CUSTOMER]: "Xem danh sách khách hàng",
    [GROUP_DICT.UPDATE_CUSTOMER]: "Duyệt thông tin khách hàng",
    [GROUP_DICT.VIEW_EMPLOYEE]: "Xem danh sách nhân viên",
    [GROUP_DICT.UPDATE_EMPLOYEE]: "Tạo cập nhật nhân viên và phân quyền",
    [GROUP_DICT.VIEW_TRANSACTION]: "Xem lịch sử giao dịch",
    [GROUP_DICT.VIEW_TRANSACTION_ORDER]: "Xem lệnh nạp rút tiền",
    [GROUP_DICT.UPDATE_TRANSACTION_ORDER]: "Duyệt/Hủy lệnh nạp rút tiền từ khách hàng",
    [GROUP_DICT.UPDATE_EMPLOYEE_TRANSACTION_ORDER]: "Duyệt/Hủy lệnh nạp rút tiền từ nhân viên",
    [GROUP_DICT.CREATE_TRANSACTION_ORDER]: "Tạo lệnh nạp tiền",
    [GROUP_DICT.VIEW_BANK_ACCOUNT]: "Xem TK ngân hàng",
    [GROUP_DICT.UPDATE_BANK_ACCOUNT]: "Tạo, cập nhật TK ngân hàng",
    [GROUP_DICT.VIEW_OFFER]: "Xem lệnh đặt mua, bán",
    [GROUP_DICT.VIEW_TRADE]: "Xem giao dịch mua bán",
    [GROUP_DICT.VIEW_PAYMENT_ORDER]: "Xem lệnh thanh toán",
    [GROUP_DICT.UPDATE_PAYMENT_ORDER]: "Cập nhật lệnh thanh toán",
    [GROUP_DICT.CONFIRM_PAYMENT_ORDER]: "Xác nhận lệnh thanh toán",
    [GROUP_DICT.VIEW_POLL]: "Xem biểu quyết",
    [GROUP_DICT.UPDATE_POLL]: "Cập nhật biểu quyết",
}

export const GROUP_GROUP_DISPLAY_DICT = {
    [GROUP_DICT.ADMIN]: "Siêu phân quyền",
    [GROUP_DICT.VIEW_PROPERTY]: "Sản phẩm đầu tư",
    [GROUP_DICT.UPDATE_PROPERTY]: "Sản phẩm đầu tư",
    [GROUP_DICT.CONFIRM_PROPERTY]: "Sản phẩm đầu tư",
    [GROUP_DICT.VIEW_CUSTOMER]: "Khách hàng",
    [GROUP_DICT.UPDATE_CUSTOMER]: "Khách hàng",
    [GROUP_DICT.VIEW_EMPLOYEE]: "Nhân viên",
    [GROUP_DICT.UPDATE_EMPLOYEE]: "Nhân viên",
    [GROUP_DICT.VIEW_TRANSACTION]: "Tài chính",
    [GROUP_DICT.VIEW_TRANSACTION_ORDER]: "Tài chính",
    [GROUP_DICT.CREATE_TRANSACTION_ORDER]: "Tài chính",
    [GROUP_DICT.VIEW_BANK_ACCOUNT]: "Tài chính",
    [GROUP_DICT.UPDATE_BANK_ACCOUNT]: "Tài chính",
    [GROUP_DICT.VIEW_OFFER]: "Tài chính",
    [GROUP_DICT.VIEW_TRADE]: "Tài chính",
    [GROUP_DICT.VIEW_PAYMENT_ORDER]: "Thanh toán",
    [GROUP_DICT.UPDATE_PAYMENT_ORDER]: "Thanh toán",
    [GROUP_DICT.CONFIRM_PAYMENT_ORDER]: "Thanh toán",
    [GROUP_DICT.VIEW_POLL]: "Biểu quyết",
    [GROUP_DICT.UPDATE_POLL]: "Biểu quyết",
    [GROUP_DICT.UPDATE_TRANSACTION_ORDER]: "Tài chính",
    [GROUP_DICT.UPDATE_EMPLOYEE_TRANSACTION_ORDER]: "Tài chính",
}
