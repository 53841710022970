import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import MailNotification from "../../../../components/MailNotification";
import AppNotification from "../../../../components/AppNotification";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import {switchLanguage} from "redux/actions/Setting";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import {getNotifications} from "../../../../redux/actions";
import {Badge} from "@material-ui/core";

const Index =({onToggleCollapsedNav})=> {

  const dispatch = useDispatch();
  const { locale } = useSelector(({settings}) => settings);
  const [langSwitcher,setLangSwitcher]=useState(false);
  const [mailNotification,setMailNotification]=useState(false);
  const [appNotification,setAppNotification]=useState(false);
  const {showDropDownNotification, notifications, notificationCount} = useSelector(({commonData}) => commonData)

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification)
  };

  const onMailNotificationSelect = () => {
    setMailNotification(!mailNotification)
  };
  const onLangSwitcherSelect = (event) => {
    setLangSwitcher(!langSwitcher);
  };

  const handleRequestClose = () => {
    setLangSwitcher(false);
    setMailNotification(false);
  };

  useEffect(() => {
    dispatch(getNotifications())
    const interval = setInterval(() => {
      dispatch(getNotifications())
    }, 30000);
    return () => clearInterval(interval);
  }, []);


  const onSwitchLanguage = (lang) => {
    dispatch(switchLanguage(lang))
  };

    return (
      <AppBar className="app-main-header">
        <Toolbar className="app-toolbar" disableGutters={false}>

          <IconButton className={`jr-menu-icon mr-3 d-block d-xl-none`} aria-label="Menu"
                      onClick={onToggleCollapsedNav}>
            <span className="menu-icon"/>
          </IconButton>

          <Link className="app-logo mr-2 d-none d-sm-block" to="/">
            <img src={require("assets/images/logo-houze-invest.svg")} alt="Jambo" title="Jambo"/>
          </Link>


          {/*<SearchBox styleName="d-none d-lg-block" placeholder=""*/}
          {/*           onChange={updateSearchText}*/}
          {/*           value={searchText}/>*/}

          <ul className="header-notifications list-inline ml-auto">
            {/*<li className="list-inline-item">*/}
            {/*  <Dropdown*/}
            {/*    className="quick-menu app-notification"*/}
            {/*    isOpen={apps}*/}
            {/*    toggle={onAppsSelect}>*/}

            {/*    <DropdownToggle*/}
            {/*      className="d-inline-block"*/}
            {/*      tag="span"*/}
            {/*      data-toggle="dropdown">*/}
            {/*      <span className="app-notification-menu">*/}
            {/*        <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>*/}
            {/*        <span>Apps</span>*/}
            {/*      </span>*/}
            {/*    </DropdownToggle>*/}

            {/*    <DropdownMenu>*/}
            {/*      {Apps()}*/}
            {/*    </DropdownMenu>*/}
            {/*  </Dropdown>*/}
            {/*</li>*/}
            {/*<li className="d-inline-block d-lg-none list-inline-item">*/}
            {/*  <Dropdown*/}
            {/*    className="quick-menu nav-searchbox"*/}
            {/*    isOpen={searchBox}*/}
            {/*    toggle={onSearchBoxSelect}>*/}

            {/*    <DropdownToggle*/}
            {/*      className="d-inline-block"*/}
            {/*      tag="span"*/}
            {/*      data-toggle="dropdown">*/}
            {/*      <IconButton className="icon-btn">*/}
            {/*        <i className="zmdi zmdi-search zmdi-hc-fw"/>*/}
            {/*      </IconButton>*/}
            {/*    </DropdownToggle>*/}

            {/*    <DropdownMenu right className="p-0">*/}
            {/*      <SearchBox styleName="search-dropdown" placeholder=""*/}
            {/*                 onChange={updateSearchText}*/}
            {/*                 value={searchText}/>*/}
            {/*    </DropdownMenu>*/}
            {/*  </Dropdown>*/}
            {/*</li>*/}
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={langSwitcher}
                toggle={onLangSwitcherSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}/>
                </DropdownMenu>
              </Dropdown>


            </li>
            <li className="list-inline-item app-tour">
              <Dropdown
                className="quick-menu"
                isOpen={appNotification}
                toggle={onAppNotificationSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">

                      <Badge badgeContent={notificationCount} color="error" className={`animated infinite ${notificationCount&&!appNotification&&'wobble'}`} max={99}>
                        <IconButton className="icon-btn">
                          <i className="zmdi zmdi-notifications-none"/>
                        </IconButton>
                      </Badge>
                </DropdownToggle>

                <DropdownMenu right>
                  <CardHeader styleName="align-items-center"
                              heading='Thông báo'/>
                  <AppNotification notifications={notifications} onAppNotificationSelect={onAppNotificationSelect}/>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item mail-tour">
              <Dropdown
                className="quick-menu"
                isOpen={mailNotification}
                toggle={onMailNotificationSelect}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">

                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw"/>
                  </IconButton>
                </DropdownToggle>


                <DropdownMenu right>
                  <CardHeader styleName="align-items-center"
                              heading='Tin mới'/>
                  <MailNotification/>
                </DropdownMenu>
              </Dropdown>
            </li>

          </ul>

          <div className="ellipse-shape"/>
        </Toolbar>
      </AppBar>
    );
  };


export default withRouter(Index);
