import {
    GET_PROJECT_DEVELOPERS,
    GET_PROJECT_DEVELOPERS_SUCCESS,
    CREATE_PROJECT_DEVELOPERS,
    CREATE_PROJECT_DEVELOPERS_SUCCESS,
    CREATE_PROJECT_DEVELOPERS_BAD,
    CANCEL_ACTION_PROJECT_DEVELOPERS,
    DETAIL_PROJECT_DEVELOPER_SUCCESS,
    UPDATE_PROJECT_DEVELOPER,
    UPDATE_PROJECT_DEVELOPER_BAD,
    UPDATE_PROJECT_DEVELOPER_SUCCESS,
    GET_AUTOCOMPLETE_PROJECT_DEVELOPERS,
    GET_AUTOCOMPLETE_PROJECT_DEVELOPERS_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    data: null,
    loader: true,
    errors: {},
    saving: false,
    detail: {},
    autocomplete:[],
    autocompleteLoading:false

};


export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_PROJECT_DEVELOPERS: {
            return {
                ...state,
                loader: true
            }
        }
        case GET_PROJECT_DEVELOPERS_SUCCESS: {
            return {
                ...INIT_STATE,
                data: action.payload,
                loader: false
            }
        }
        case CREATE_PROJECT_DEVELOPERS: {
            // state['saving'] = true
            // state['errors'] = {}
            return {
                ...state,
                saving: true,
                errors: {}
            }
        }
        case CREATE_PROJECT_DEVELOPERS_SUCCESS: {
            return {
                ...state,
                saving: false
            }
        }
        case CREATE_PROJECT_DEVELOPERS_BAD: {
            return {
                ...state,
                saving: false,
                errors: action.payload
            }
        }
        case CANCEL_ACTION_PROJECT_DEVELOPERS: {
            return {
                ...state,
                errors: {}
            }
        }
        case DETAIL_PROJECT_DEVELOPER_SUCCESS: {
            return {
                ...state,
                detail: action.payload
            }
        }
        case UPDATE_PROJECT_DEVELOPER: {
            return {
                ...state,
                saving: true
            }
        }
        case UPDATE_PROJECT_DEVELOPER_BAD: {
            return {
                ...state,
                saving: false,
                errors: action.payload
            }
        }
        case GET_AUTOCOMPLETE_PROJECT_DEVELOPERS: {
            return {
                ...state,
                autocomplete:[],
                autocompleteLoading:true
            }
        }
        case GET_AUTOCOMPLETE_PROJECT_DEVELOPERS_SUCCESS: {
            return {
                ...state,
                autocomplete: action.payload,
                autocompleteLoading: false
            }
        }
        default:
            return state;
    }
}
