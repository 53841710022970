import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Service from "../../util/services/propertyItemApi";

import {
    GET_PROPERTY_ITEMS,
    UPDATE_PROPERTY_ITEM,
    CREATE_PROPERTY_ITEM,
    DELETE_PROPERTY_ITEM
} from "constants/ActionTypes";

import {
    getPropertyItemsSuccess,
    getPropertyItems,
    updatePropertyItemSuccess,
    openCreateItemDialog,
    deletePropertyItemSuccess,
    createPropertyItemSuccess, cancelItemAction, itemBadRequest

} from "redux/actions/PropertyItem";

import {showMessage} from "redux/actions/Common"


function* GetPropertyItems(actions) {
    try {
        const response = yield call(Service.getPropertyItems, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getPropertyItemsSuccess(response.data.results));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function* CreatePropertyItems(actions) {
    try {
        const response = yield call(Service.createPropertyItem, actions.payload);
        if (response.message) {
            if(response.response.status === 400){
                yield put(itemBadRequest(response.response.data))
            }else{
                yield put(showMessage(response.message));
            }
        } else {
            yield put(getPropertyItems({property_id:actions.payload.property}))
            yield put(createPropertyItemSuccess())
            // history.push(window.location.pathname + window.location.search)
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


function* UpdatePropertyItem(actions) {
    try {
        const response = yield call(Service.updatePropertyItem, actions.payload);
        if (response.message) {
            if(response.response.status === 400){
                yield put(itemBadRequest(response.response.data))
            }else{
                yield put(showMessage(response.message));
            }
        } else {
            // history.push(window.location.pathname + window.location.search)
            yield put(getPropertyItems({property_id:actions.payload.property_id}))
            yield put(updatePropertyItemSuccess())
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function* DeletePropertyItem(actions) {
    try {
        const response = yield call(Service.deletePropertyItem, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
            yield put(cancelItemAction());
        } else {
            // history.push(window.location.pathname + window.location.search)
            yield put(getPropertyItems({property_id:actions.payload.property_id}))
            yield put(deletePropertyItemSuccess())
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

// ------------------------------------------------------------------

export function* watchGetPropertyItems() {
    yield takeEvery(GET_PROPERTY_ITEMS, GetPropertyItems);
}

export function* watchCreatePropertyItem() {
    yield takeEvery(CREATE_PROPERTY_ITEM, CreatePropertyItems);
}

export function* watchUpdatePropertyItem() {
    yield takeEvery(UPDATE_PROPERTY_ITEM, UpdatePropertyItem);
}

export function* watchDeletePropertyItem() {
    yield takeEvery(DELETE_PROPERTY_ITEM, DeletePropertyItem);
}

//-------------------------------------------
export default function* rootSaga() {
    yield all([
        fork(watchGetPropertyItems),
        fork(watchCreatePropertyItem),
        fork(watchUpdatePropertyItem),
        fork(watchDeletePropertyItem),
    ]);
}
