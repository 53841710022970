import {ROUTE_NAME, routeTo} from './routes';
import axiosInstance from "../axiosApi";
import {objectToFormData} from "../function/objectToFormData";

export default {
    // called when the user attempts to log in
    // login: (username, password) => {
    //     let url = routeTo(ROUTE_NAME.AUTH.AUTH_TOKEN);
    //     const request = new Request(routeTo(ROUTE_NAME.AUTH.AUTH_TOKEN), {
    //         method: 'POST',
    //         body: JSON.stringify({ username, password }),
    //         headers: new Headers({ 'Content-Type': 'application/json' }),
    //     });
    //     return fetch(request)
    //         .then(response => {
    //             if (response.status < 200 || response.status >= 300) {
    //                 throw new Error(response.statusText);
    //             }
    //             return response.json();
    //         })
    //         .then((token) => {
    //             localStorage.setItem('access', token.access);
    //             localStorage.setItem('refresh', token.refresh);
    //             return token
    //         }).catch(error => error);
    // },
    login: async (username, password) => {
        return await axiosInstance.post(routeTo(ROUTE_NAME.AUTH.AUTH_TOKEN), {username, password}).then(response => {
            const {access, refresh} = response.data
            localStorage.setItem('access', access);
            localStorage.setItem('refresh', refresh);
            return response
        }).catch(error => error)
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async (params) => {
        const accessToken = localStorage.getItem('access');

        if (accessToken) {
            return Promise.resolve();
        }

        return Promise.reject();

    },
    getProfile: () => {
        return axiosInstance.get(routeTo(ROUTE_NAME.AUTH.PROFILE)).then(response => {
            return response
        }).catch(error => error)
    },

    // called when the user navigates to a new location, to check for permissions / roles
    getGroups: async () => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.AUTH.GET_GROUPS) + 'limit=100').then(response => {
            return response
        }).catch(error => error)
    },

    changePassword: async (data) => {
        return await axiosInstance.put(routeTo(ROUTE_NAME.AUTH.CHANGE_PASSWORD), data).then(response => {
            return response
        }).catch(error => error)
    },

    updateProfile: async (data) => {
        return await axiosInstance.patch(routeTo(ROUTE_NAME.AUTH.UPDATE_PROFILE), objectToFormData(data)).then(response => {
            return response
        }).catch(error => error)
    },

};
