import {
    GET_PROPERTIES,
    GET_PROPERTIES_SUCCESS,
    CREATE_PROPERTY,
    CREATE_PROPERTY_SUCCESS,
    CREATE_PROPERTY_BAD,
    DETAIL_PROPERTY,
    DETAIL_PROPERTY_SUCCESS,
    UPDATE_PROPERTY,
    UPDATE_PROPERTY_BAD,
    UPDATE_PROPERTY_SUCCESS, CANCEL_PROPERTY_ACTION,
} from "constants/ActionTypes";

const INIT_STATE = {
    data: null,
    loader: true,
    errors: {},
    saving: false,
    detail: {},
    detailLoading: false

};


export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_PROPERTIES: {
            return {
                ...state,
                loader: true
            }
        }
        case GET_PROPERTIES_SUCCESS: {
            return {
                ...INIT_STATE,
                data: action.payload,
                loader: false
            }
        }
        case CREATE_PROPERTY: {
            return {
                ...state,
                saving: true,
                errors: {}
            }
        }
        case CREATE_PROPERTY_SUCCESS: {
            return {
                ...state,
                saving: false
            }
        }
        case CREATE_PROPERTY_BAD: {
            return {
                ...state,
                saving: false,
                errors: action.payload
            }
        }
        case DETAIL_PROPERTY: {
            return {
                ...INIT_STATE,
                detailLoading: true,
                saving: false,
            }
        }
        case DETAIL_PROPERTY_SUCCESS: {
            return {
                ...state,
                detailLoading: false,
                detail: action.payload
            }
        }
        case CANCEL_PROPERTY_ACTION: {
            return {
                ...state,
                errors: {}
            }
        }
        case UPDATE_PROPERTY: {
            return {
                ...state,
                saving: true
            }
        }
        case UPDATE_PROPERTY_BAD: {
            return {
                ...state,
                saving: false,
                errors: action.payload
            }
        }
        case UPDATE_PROPERTY_SUCCESS: {
            return {
                ...state,
                saving: false
            }
        }
        default:
            return state;
    }
}
