import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import generalApi from "../../util/services/generalApi";
import {GET_NOTIFICATIONS} from "constants/ActionTypes";

import {getNotificationsSuccess, showMessage} from "redux/actions/Common"
import {ROUTE_NAME} from "../../util/services/routes";


function* GetNotifications() {
    try {
        const response = yield call(generalApi.getList, {filters:{}, routeName:ROUTE_NAME.NOTIFICATION.GET_NOTIFICATIONS});
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getNotificationsSuccess(response.data));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

// ------------------------------------------------------------------

export function* watchNotification() {
    yield takeEvery(GET_NOTIFICATIONS, GetNotifications);
}

//-------------------------------------------
export default function* rootSaga() {
    yield all([
        fork(watchNotification),
    ]);
}
