import React from "react";


// refund type
export const TYPE_REFUND_DICT = {
    FIXED_REFUND: 0,
    YEAR_TERM_RATE_REFUND: 1
}

export const TYPE_REFUND_DISPLAY_DICT = {
    [TYPE_REFUND_DICT.FIXED_REFUND]: 'Cố định theo chứng chỉ',
    [TYPE_REFUND_DICT.YEAR_TERM_RATE_REFUND]: 'Trả theo lãi suất',
}

export const TYPE_REFUND_UNIT_DICT = {
    [TYPE_REFUND_DICT.FIXED_REFUND]: 'VND',
    [TYPE_REFUND_DICT.YEAR_TERM_RATE_REFUND]: '%',
}

export const TYPE_REFUND_DECIMAL_PLACE_DICT = {
    [TYPE_REFUND_DICT.FIXED_REFUND]: 0,
    [TYPE_REFUND_DICT.YEAR_TERM_RATE_REFUND]: 2,
}

/// type property

export const TYPE_PROPERTY_DICT = {
    APARTMENT: 0,
    STUDIO: 1,
    OFFICETEL: 2,
    SHOP_HOUSE: 3,
    DUAL_KEY: 4,
    DUPLEX: 5,
    SKY_VILLA: 6,
    CONDOTEL: 7,
    CONDOMINIUM: 8,
    PENHOUSE: 9,

    TOWNHOUSE: 50,
    VILLA: 51,
    GROUND: 52
}


export const TYPE_PROPERTY_DISPLAY_DICT = {
    [TYPE_PROPERTY_DICT.APARTMENT]: 'Căn hộ',
    // [TYPE_PROPERTY_DICT.STUDIO]: 'Studio',
    // [TYPE_PROPERTY_DICT.OFFICETEL]: 'Officetel',
    // [TYPE_PROPERTY_DICT.SHOP_HOUSE]: 'Shop House',
    // [TYPE_PROPERTY_DICT.DUAL_KEY]: 'Căn hộ Dual key',
    // [TYPE_PROPERTY_DICT.DUPLEX]: 'Căn hộ Duplex',
    // [TYPE_PROPERTY_DICT.SKY_VILLA]: 'Sky villa',
    // [TYPE_PROPERTY_DICT.CONDOTEL]: 'Condotel',
    // [TYPE_PROPERTY_DICT.CONDOMINIUM]: 'Condominium',
    // [TYPE_PROPERTY_DICT.PENHOUSE]: 'Pen house',

    [TYPE_PROPERTY_DICT.TOWNHOUSE]: 'Nhà phố',
    [TYPE_PROPERTY_DICT.VILLA]: 'Biệt thự',
    [TYPE_PROPERTY_DICT.GROUND]: 'Đất nền',
}

//status

export const STATUS_PROPERTY_DICT = {
    INITIALIZING: 0,
    AWAITING_APPROVAL: 1,
    CALLING_FOR_CAPITAL: 2,
    COMPLETE_CAPITAL_CALL: 3,
    INVESTMENT_IS_COMPLETE: 4,
    FAIL_CAPITAL_CALL: 5,
}

export const STATUS_PROPERTY_DISPLAY_DICT = {
    [STATUS_PROPERTY_DICT.INITIALIZING]: 'Đang khởi tạo',
    [STATUS_PROPERTY_DICT.AWAITING_APPROVAL]: 'Đang chờ duyệt',
    [STATUS_PROPERTY_DICT.CALLING_FOR_CAPITAL]: 'Đang gọi vốn',
    [STATUS_PROPERTY_DICT.COMPLETE_CAPITAL_CALL]: 'Hoàn tất gọi vốn',
    [STATUS_PROPERTY_DICT.INVESTMENT_IS_COMPLETE]: 'Hoàn tất đầu tư',
    [STATUS_PROPERTY_DICT.FAIL_CAPITAL_CALL]: 'Gọi vốn không thành công',
}

export const STATUS_PROPERTY_STYLE_DISPLAY_DICT = {
    [STATUS_PROPERTY_DICT.INITIALIZING]: 'text-white bg-grey',
    [STATUS_PROPERTY_DICT.AWAITING_APPROVAL]: 'bg-secondary',
    [STATUS_PROPERTY_DICT.CALLING_FOR_CAPITAL]: 'text-white bg-green',
    [STATUS_PROPERTY_DICT.COMPLETE_CAPITAL_CALL]: 'text-white bg-info',
    [STATUS_PROPERTY_DICT.INVESTMENT_IS_COMPLETE]: 'bg-primary text-white',
    [STATUS_PROPERTY_DICT.FAIL_CAPITAL_CALL]: 'bg-danger text-white',
}


export const PROPERTY_LEVEL_DICT = {
    PROJECT: 0,
    PRODUCT: 1,
}


export const PROPERTY_LEVEL_DISPLAY_DICT = {
    [PROPERTY_LEVEL_DICT.PROJECT]: 'Dự án',
    [PROPERTY_LEVEL_DICT.PRODUCT]: 'Sản phẩm',
}


export const PROPERTY_LEVEL_STYLE_DISPLAY_DICT = {
    [PROPERTY_LEVEL_DICT.PROJECT]: 'text-white bg-green',
    [PROPERTY_LEVEL_DICT.PRODUCT]: 'text-white bg-info',
}


export const CONTRACT_PRODUCT_OWNER_TYPE_DICT = {
    TYPE_A: 0,
    TYPE_B: 1,
    TYPE_C: 2,
}


export const CONTRACT_PRODUCT_OWNER_TYPE_DISPLAY_DICT = {
    [CONTRACT_PRODUCT_OWNER_TYPE_DICT.TYPE_A]: 'Chủ đầu tư của Dự án ... theo ... số ... ngày ... của ...',
    [CONTRACT_PRODUCT_OWNER_TYPE_DICT.TYPE_B]: 'Bên nhận chuyển nhượng Dự án ... theo ... số ... ngày ... với ...',
    [CONTRACT_PRODUCT_OWNER_TYPE_DICT.TYPE_C]: 'Bên mua / nhận chuyển nhượng ... theo Hợp đồng ... số ... ngày ... với ...',
}




export const PROPERTY_ARCHIVE_DICT = {
    NOT_ARCHIVE: false,
    ARCHIVED: true,
}


export const PROPERTY_ARCHIVE_DISPLAY_DICT = {
    [PROPERTY_ARCHIVE_DICT.NOT_ARCHIVE]: 'Đang hoạt động',
    [PROPERTY_ARCHIVE_DICT.ARCHIVED]: 'Đã lưu trữ',
}

export const PROPERTY_COMING_SOON_DICT = {
    COMING: true,
    NOT_COMING: false,
}


export const PROPERTY_COMING_SOON_DISPLAY_DICT = {
    [PROPERTY_COMING_SOON_DICT.COMING]: 'Bật coming soon',
    [PROPERTY_COMING_SOON_DICT.NOT_COMING]: 'Tắt Coming soon',
}
