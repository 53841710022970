import axiosInstance from "../axiosApi";
import {ROUTE_NAME, routeTo} from './routes';

export default {
    getPropertyItems: async ({property_id}) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.ITEM.GET_PROPERTY_ITEMS, {property_id})).then(response => {
            return response
        }).catch(error => error)
    },
    createPropertyItem: async (data) => {
        return await axiosInstance.post(routeTo(ROUTE_NAME.ITEM.CREATE_PROPERTY_ITEM), data).then(response => {
            return response
        }).catch(error => error)
    },
    updatePropertyItem: async ({data, id}) => {
        return await axiosInstance.patch(routeTo(ROUTE_NAME.ITEM.UPDATE_PROPERTY_ITEM, {}, {id}), data).then(response => {
            return response
        }).catch(error => error)
    },

    deletePropertyItem: async ({id}) => {
        return await axiosInstance.delete(routeTo(ROUTE_NAME.ITEM.UPDATE_PROPERTY_ITEM, {}, {id})).then(response => {
            return response
        }).catch(error => error)
    },
};
