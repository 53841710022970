import {objectToQueryString} from "../function/queryString";
import {API_URL, PROPERTY_API_URL} from "../../config";

const {compile} = require("path-to-regexp");

export const ROUTE_NAME = {
    AUTH: {
        REFRESH_TOKEN: 'auth.refresh.token',
        AUTH_TOKEN: 'auth.login.token',
        PROFILE: 'auth.get.profile',
        GET_GROUPS: 'GET_GROUPS',
        CHANGE_PASSWORD: 'CHANGE_PASSWORD',
        UPDATE_PROFILE: 'UPDATE_PROFILE',
    },
    ACCOUNT: {
        GET_CUSTOMERS: 'account.get.customers',
        GET_CUSTOMER_DETAIL: 'account.get.customer_detail',
        UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
        EXPORT_CUSTOMER: 'EXPORT_CUSTOMER',
    },
    PROJECT: {
        DEVELOPERS: 'project.get.developers',
        DEVELOPERS_CREATE: 'project.get.developers_create',
        DEVELOPERS_UPDATE: 'project.get.developers_update',
        DEVELOPERS_DETAIL: 'project.get.developers_detail',
        EXPORT_DEVELOPER: 'EXPORT_DEVELOPER',

        AUTOCOMPLETE_PD: 'AUTOCOMPLETE_PD',
    },
    PROPERTY: {
        PROPERTIES: 'PROPERTIES',
        PROPERTY_CREATE: 'PROPERTY_CREATE',
        PROPERTY_UPDATE: 'PROPERTY_UPDATE',
        PROPERTY_DETAIL: 'PROPERTY_DETAIL',
        EXPORT_PROPERTY: 'EXPORT_PROPERTY',

        AUTOCOMPLETE_PROPERTY: 'AUTOCOMPLETE_PROPERTY',
    },
    ADDRESS: {
        PROVINCES: 'PROVINCES',
        DISTRICTS: 'DISTRICTS',
        WARDS: 'WARDS'
    },
    IMAGE: {
        UPLOAD_PROPERTY_IMAGES: 'UPLOAD_PROPERTY_IMAGES',
        DELETE_IMAGE: 'DELETE_IMAGE',
        GET_PROPERTY_IMAGES: 'GET_PROPERTY_IMAGES'
    },
    ITEM: {
        CREATE_PROPERTY_ITEM: 'CREATE_PROPERTY_ITEM',
        UPDATE_PROPERTY_ITEM: 'UPDATE_PROPERTY_ITEM',
        DELETE_PROPERTY_ITEM: 'DELETE_PROPERTY_ITEM',
        GET_PROPERTY_ITEMS: 'GET_PROPERTY_ITEMS',

        AUTOCOMPLETE_ITEM: 'AUTOCOMPLETE_ITEM',
    },
    EMPLOYEE: {
        GET_EMPLOYEES: 'GET_EMPLOYEES',
        CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
        UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',
        EXPORT_EMPLOYEE: 'EXPORT_EMPLOYEE'
    },
    BANK_ACCOUNT: {
        GET_BANK_ACCOUNTS: 'GET_BANK_ACCOUNTS',
        CREATE_BANK_ACCOUNT: 'CREATE_BANK_ACCOUNT',
        UPDATE_BANK_ACCOUNT: 'UPDATE_BANK_ACCOUNT',
        EXPORT_BANK_ACCOUNT: 'EXPORT_BANK_ACCOUNT'
    },
    TRANSACTION_ORDER: {
        GET_TRANSACTION_ORDERS: 'GET_TRANSACTION_ORDERS',
        CREATE_TRANSACTION_ORDERS: 'CREATE_TRANSACTION_ORDERS',
        UPDATE_TRANSACTION_ORDER: 'UPDATE_TRANSACTION_ORDER',
        EXPORT_TRANSACTION_ORDER: 'EXPORT_TRANSACTION_ORDER',
    },
    TRANSACTION: {
        GET_TRANSACTIONS: 'GET_TRANSACTIONS',
        EXPORT_TRANSACTION: 'EXPORT_TRANSACTION'
    },
    TRADE: {
        GET_TRADES: 'GET_TRADES',
        EXPORT_TRADE: 'EXPORT_TRADE'
    },
    OFFER: {
        GET_OFFERS: 'GET_OFFERS',
        EXPORT_OFFER: 'EXPORT_OFFER'
    },
    PORTFOLIO: {
        GET_PORTFOLIOS: 'GET_PORTFOLIOS',
        EXPORT_PORTFOLIO: 'EXPORT_PORTFOLIO'
    },
    PAYMENT_ORDER: {
        GET_PAYMENT_ORDERS: 'GET_PAYMENT_ORDERS',
        CREATE_PAYMENT_ORDER: 'CREATE_PAYMENT_ORDER',
        UPDATE_PAYMENT_ORDER: 'UPDATE_PAYMENT_ORDER',
        EXPORT_PAYMENT_ORDER: 'EXPORT_PAYMENT_ORDER',
        CROSS_CHECK_REPORT: 'CROSS_CHECK_REPORT',

    },
    POLL: {
        GET_POLLS: 'GET_POLLS',
        CREATE_POLL: 'CREATE_POLL',
        UPDATE_POLL: 'UPDATE_POLL',
        EXPORT_POLL: 'EXPORT_POLL'
    },
    LOG_ENTRY: {
        GET_LOG_ENTRIES: 'GET_LOG_ENTRIES',
    },
    NOTIFICATION: {
        GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    },
    REMOTE: {
        PROPERTY_ID: {
            PROJECT: {
                PROJECTS: 'propertyID.projects',
                PROJECT_DETAIL: 'propertyID.project.detail',
            }
        }
    },
    PRODUCT_OWNER: {
        GET_PRODUCT_OWNERS: 'GET_PRODUCT_OWNERS',
        CREATE_PRODUCT_OWNER: 'CREATE_PRODUCT_OWNER',
        UPDATE_PRODUCT_OWNER: 'UPDATE_PRODUCT_OWNER',
        DELETE_PRODUCT_OWNER: 'DELETE_PRODUCT_OWNER',
    },

    RE_VERIFY: {
        GET_RE_VERIFIES: 'GET_RE_VERIFIES',
        CREATE_RE_VERIFY: 'CREATE_RE_VERIFY',
        UPDATE_RE_VERIFY: 'UPDATE_RE_VERIFY',
    },
}


const routes_config = {
    [ROUTE_NAME.AUTH.AUTH_TOKEN]: '/oauth/api-token-auth/',
    [ROUTE_NAME.AUTH.PROFILE]: '/oauth/profile/',
    [ROUTE_NAME.AUTH.REFRESH_TOKEN]: '/oauth/api-token-refresh/',
    [ROUTE_NAME.AUTH.GET_GROUPS]: '/profile/groups/',
    [ROUTE_NAME.AUTH.CHANGE_PASSWORD]: '/oauth/change-password/',
    [ROUTE_NAME.AUTH.UPDATE_PROFILE]: '/profile/',

    [ROUTE_NAME.ACCOUNT.GET_CUSTOMERS]: '/customers/',
    [ROUTE_NAME.ACCOUNT.GET_CUSTOMER_DETAIL]: '/customers/:id/',
    [ROUTE_NAME.ACCOUNT.UPDATE_CUSTOMER]: '/customers/:id/',
    [ROUTE_NAME.ACCOUNT.EXPORT_CUSTOMER]: '/customers/export/',

 //Project developer
    [ROUTE_NAME.PROJECT.DEVELOPERS]: '/project-developers/',
    [ROUTE_NAME.PROJECT.DEVELOPERS_CREATE]: '/project-developers/',
    [ROUTE_NAME.PROJECT.DEVELOPERS_UPDATE]: '/project-developers/:id/',
    [ROUTE_NAME.PROJECT.DEVELOPERS_DETAIL]: '/project-developers/:id/',
    [ROUTE_NAME.PROJECT.EXPORT_DEVELOPER]: '/project-developers/export/',

    [ROUTE_NAME.PROJECT.AUTOCOMPLETE_PD]: '/autocomplete/ProjectDeveloper/',

 // PROPERTY
    [ROUTE_NAME.PROPERTY.PROPERTIES]: '/properties/',
    [ROUTE_NAME.PROPERTY.PROPERTY_CREATE]: '/properties/',
    [ROUTE_NAME.PROPERTY.PROPERTY_DETAIL]: '/properties/:id/',
    [ROUTE_NAME.PROPERTY.PROPERTY_UPDATE]: '/properties/:id/',
    [ROUTE_NAME.PROPERTY.EXPORT_PROPERTY]: '/properties/export/',

    [ROUTE_NAME.PROPERTY.AUTOCOMPLETE_PROPERTY]: '/autocomplete/Property/',


//Address
    [ROUTE_NAME.ADDRESS.PROVINCES]: '/autocomplete/Province/',
    [ROUTE_NAME.ADDRESS.DISTRICTS]: '/autocomplete/District/',
    [ROUTE_NAME.ADDRESS.WARDS]: '/autocomplete/Ward/',

// property ID
    [ROUTE_NAME.REMOTE.PROPERTY_ID.PROJECT.PROJECTS]: '/property/project/',
    [ROUTE_NAME.REMOTE.PROPERTY_ID.PROJECT.PROJECT_DETAIL]: '/property/project/:id/',

// Image
    [ROUTE_NAME.IMAGE.UPLOAD_PROPERTY_IMAGES]: '/properties/:id/images/',
    [ROUTE_NAME.IMAGE.GET_PROPERTY_IMAGES]: '/properties/:id/images/',
    [ROUTE_NAME.IMAGE.DELETE_IMAGE]: '/images/:id/',

// Property Item
    [ROUTE_NAME.ITEM.CREATE_PROPERTY_ITEM]: '/items/',
    [ROUTE_NAME.ITEM.GET_PROPERTY_ITEMS]: '/items/',
    [ROUTE_NAME.ITEM.UPDATE_PROPERTY_ITEM]: '/items/:id/',
    [ROUTE_NAME.ITEM.DELETE_PROPERTY_ITEM]: '/items/:id/',

    [ROUTE_NAME.ITEM.AUTOCOMPLETE_ITEM]: '/autocomplete/Item/',


// Employee
    [ROUTE_NAME.EMPLOYEE.GET_EMPLOYEES]: '/employees/',
    [ROUTE_NAME.EMPLOYEE.CREATE_EMPLOYEE]: '/employees/',
    [ROUTE_NAME.EMPLOYEE.UPDATE_EMPLOYEE]: '/employees/:id/',
    [ROUTE_NAME.EMPLOYEE.EXPORT_EMPLOYEE]: '/employees/export/',

// Bank account
    [ROUTE_NAME.BANK_ACCOUNT.CREATE_BANK_ACCOUNT]: '/bank-accounts/',
    [ROUTE_NAME.BANK_ACCOUNT.GET_BANK_ACCOUNTS]: '/bank-accounts/',
    [ROUTE_NAME.BANK_ACCOUNT.UPDATE_BANK_ACCOUNT]: '/bank-accounts/:id/',
    [ROUTE_NAME.BANK_ACCOUNT.EXPORT_BANK_ACCOUNT]: '/bank-accounts/export/',

// transaction order
    [ROUTE_NAME.TRANSACTION_ORDER.GET_TRANSACTION_ORDERS]: '/transaction-orders/',
    [ROUTE_NAME.TRANSACTION_ORDER.CREATE_TRANSACTION_ORDERS]: '/transaction-orders/',
    [ROUTE_NAME.TRANSACTION_ORDER.UPDATE_TRANSACTION_ORDER]: '/transaction-orders/:id/',
    [ROUTE_NAME.TRANSACTION_ORDER.EXPORT_TRANSACTION_ORDER]: '/transaction-orders/export/',

// transaction
    [ROUTE_NAME.TRANSACTION.GET_TRANSACTIONS]: '/transactions/',
    [ROUTE_NAME.TRANSACTION.EXPORT_TRANSACTION]: '/transactions/export/',

// trade
    [ROUTE_NAME.TRADE.GET_TRADES]: '/trades/',
    [ROUTE_NAME.TRADE.EXPORT_TRADE]: '/trades/export/',

// offer
    [ROUTE_NAME.OFFER.GET_OFFERS]: '/offers/',
    [ROUTE_NAME.OFFER.EXPORT_OFFER]: '/offers/export/',

// trade
    [ROUTE_NAME.PORTFOLIO.GET_PORTFOLIOS]: '/portfolios/',
    [ROUTE_NAME.PORTFOLIO.EXPORT_PORTFOLIO]: '/portfolios/export/',

// payment order
    [ROUTE_NAME.PAYMENT_ORDER.GET_PAYMENT_ORDERS]: '/payment-orders/',
    [ROUTE_NAME.PAYMENT_ORDER.CREATE_PAYMENT_ORDER]: '/payment-orders/',
    [ROUTE_NAME.PAYMENT_ORDER.UPDATE_PAYMENT_ORDER]: '/payment-orders/:id/',
    [ROUTE_NAME.PAYMENT_ORDER.EXPORT_PAYMENT_ORDER]: '/payment-orders/export/',
    [ROUTE_NAME.PAYMENT_ORDER.CROSS_CHECK_REPORT]: '/payment-orders/:id/crosscheck-report/',

//
    [ROUTE_NAME.POLL.GET_POLLS]: '/polls/',
    [ROUTE_NAME.POLL.CREATE_POLL]: '/polls/',
    [ROUTE_NAME.POLL.UPDATE_POLL]: '/polls/:id/',
    [ROUTE_NAME.POLL.EXPORT_POLL]: '/polls/export/',

// log entries
    [ROUTE_NAME.LOG_ENTRY.GET_LOG_ENTRIES]: '/log-entries/',

// Notification
    [ROUTE_NAME.NOTIFICATION.GET_NOTIFICATIONS]: '/profile/notifications/',

// product owner
    [ROUTE_NAME.PRODUCT_OWNER.GET_PRODUCT_OWNERS]: '/product-owners/',
    [ROUTE_NAME.PRODUCT_OWNER.CREATE_PRODUCT_OWNER]: '/product-owners/',
    [ROUTE_NAME.PRODUCT_OWNER.UPDATE_PRODUCT_OWNER]: '/product-owners/:id/',

// re verify customer
    [ROUTE_NAME.RE_VERIFY.GET_RE_VERIFIES]: '/customers/re-verify/',
    [ROUTE_NAME.RE_VERIFY.UPDATE_RE_VERIFY]: '/customers/re-verify/:id/',

}

export const routeTo = (name, params = {}, url_params) => API_URL + compile(routes_config[name])(url_params) + objectToQueryString(params);
export const routeToPropertyID = (name, params = {}, url_params) => PROPERTY_API_URL + compile(routes_config[name])(url_params) + objectToQueryString(params);


export const routeToEndpoint = (endpoint, params = {}) => endpoint + objectToQueryString(params);
