import axiosInstance from "../axiosApi";
import {ROUTE_NAME, routeTo} from './routes';
import {objectToFormData, objectToJsonData} from "../function/objectToFormData";

export default {
    // called when the user attempts to log in
    getList: async ({filters, routeName}) => {
        return await axiosInstance.get(routeTo(routeName, filters)).then(response => {
            return response
        }).catch(error => error)
    },
    getOne: async ({url_params, routeName}) => {
        return await axiosInstance.get(routeTo(routeName, {}, url_params)).then(response => {
            return response
        }).catch(error => error)
    },
    update: async ({data, url_params={}, routeName}) => {
        return await axiosInstance.patch(routeTo(routeName, {}, url_params), objectToFormData(data), {headers: { 'content-type': 'multipart/form-data' }}).then(response => {
            return response
        }).catch(error => error)
    },

    update_json: async ({data, url_params={}, routeName}) => {
        return await axiosInstance.patch(routeTo(routeName, {}, url_params), data).then(response => {
            return response
        }).catch(error => error)
    },

    create: async ({data, url_params={}, routeName}) => {
        return await axiosInstance.post(routeTo(routeName, {}, url_params), data).then(response => {
            return response
        }).catch(error => error)
    },
    download: async ({filters, routeName, exportName='export'}) => {
        return await axiosInstance.get(routeTo(routeName, filters), {responseType:'blob', headers: { 'accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'}}).then(({data}) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', `${exportName}.xlsx`); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
        }).catch(error => error)
    },
    post_download: async ({data, url_params={}, routeName, exportName='export'}) => {
        return await axiosInstance.post(routeTo(routeName, {}, url_params), objectToJsonData(data),{responseType:'blob', headers: { 'accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'}}).
        then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', exportName+'.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(error => error)
    },
};
