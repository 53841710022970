import {
  FIXED_DRAWER,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION
} from 'constants/ActionTypes';
import {DEEP_PURPLE} from 'constants/ThemeColors';

const rltLocale = ['ar'];
const initialSettings = {
  drawerType: FIXED_DRAWER,
  themeColor: DEEP_PURPLE,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: 'vietnam',
    locale: 'vi',
    name: 'Việt Nam',
    icon: 'vn'
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    // case DRAWER_TYPE:
    //   return {
    //     ...state,
    //     drawerType: action.drawerType
    //   };
    // case THEME_COLOR:
    //   return {
    //     ...state,
    //     darkTheme: false,
    //     themeColor: action.color
    //   };
    // case DARK_THEME:
    //   return {
    //     ...state,
    //     darkTheme: !state.darkTheme
    //   };
    case SWITCH_LANGUAGE:

      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)

      };
    // case CHANGE_DIRECTION:
    //   return {
    //     ...state,
    //     isDirectionRTL: !state.isDirectionRTL
    //
    //   };

    // case CHANGE_NAVIGATION_STYLE:
    //   return {
    //     ...state,
    //     navigationStyle: action.payload
    //   };


    // case HORIZONTAL_MENU_POSITION:
    //   return {
    //     ...state,
    //     horizontalNavPosition: action.payload
    //   };


    default:
      return state;
  }
};

export default settings;
