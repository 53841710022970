import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import propertyIDService from "../../util/services/propertyIDApi";
import {
    GET_PROPERTY_ID_PROJECTS,
    DETAIL_PROPERTY_ID_PROJECT
} from "constants/ActionTypes";
import {
    getPropertyIDProjectsSuccess,
    detailPropertyIDProjectSuccess

} from "redux/actions/PropertyID";

import {showMessage} from "redux/actions/Common"


function* GetPropertyIDProjects(actions) {
    try {
        const response = yield call(propertyIDService.getPropertyIDProjects, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getPropertyIDProjectsSuccess(response.data.results));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function* DetailPropertyIDProject(actions) {
    try {
        if (actions.payload?.property_id){
            const response = yield call(propertyIDService.detailPropertyIDProject, actions.payload);
            if (response.message) {
                yield put(showMessage(response.message));
            } else {
                yield put(detailPropertyIDProjectSuccess(response.data));
            }
        }

    } catch (error) {
        yield put(showMessage(error));
    }
}


// ------------------------------------------------------------------

export function* watchPropertyIDProject() {
    yield takeEvery(GET_PROPERTY_ID_PROJECTS, GetPropertyIDProjects);
}
export function* watchDetailPropertyIDProject() {
    yield takeEvery(DETAIL_PROPERTY_ID_PROJECT, DetailPropertyIDProject);
}


//-------------------------------------------
export default function* rootSaga() {
    yield all([
        fork(watchPropertyIDProject),
        fork(watchDetailPropertyIDProject),
    ]);
}
