import React from "react";

export const TRANSACTION_ORDER_ORDER_TYPE_DICT = {
    DEPOSIT: 0,
    WITHDRAW: 1
}

export const TRANSACTION_ORDER_DISPLAY_ORDER_TYPE_DICT = {
    [TRANSACTION_ORDER_ORDER_TYPE_DICT.DEPOSIT]: 'Nạp',
    [TRANSACTION_ORDER_ORDER_TYPE_DICT.WITHDRAW]: 'Rút',
}

export const TRANSACTION_ORDER_STYLE_ORDER_TYPE_DICT = {
    [TRANSACTION_ORDER_ORDER_TYPE_DICT.DEPOSIT]: 'bg-info text-white',
    [TRANSACTION_ORDER_ORDER_TYPE_DICT.WITHDRAW]: 'bg-danger text-white',
}

export const TRANSACTION_ORDER_PAYMENT_TYPE_DICT = {
    BANK_TRANSFER: 0,
    PAYOO: 1
}

export const TRANSACTION_ORDER_DISPLAY_PAYMENT_TYPE_DICT = {
    [TRANSACTION_ORDER_PAYMENT_TYPE_DICT.BANK_TRANSFER]: 'Chuyển khoản',
    [TRANSACTION_ORDER_PAYMENT_TYPE_DICT.PAYOO]: 'Payoo',
}



export const TRANSACTION_ORDER_STATUS_DICT = {
    IN_PROCESS: 0,
    DONE: 1,
    CANCELLED: 2,
}

export const TRANSACTION_ORDER_DISPLAY_STATUS_DICT = {
    [TRANSACTION_ORDER_STATUS_DICT.IN_PROCESS]: 'Chờ xử lý',
    [TRANSACTION_ORDER_STATUS_DICT.DONE]: 'Hoàn thành',
    [TRANSACTION_ORDER_STATUS_DICT.CANCELLED]: 'Đã hủy',
}

export const TRANSACTION_ORDER_STYLE_STATUS_DICT = {
    [TRANSACTION_ORDER_STATUS_DICT.IN_PROCESS]: 'bg-secondary text-white',
    [TRANSACTION_ORDER_STATUS_DICT.DONE]: 'bg-success text-white',
    [TRANSACTION_ORDER_STATUS_DICT.CANCELLED]: 'bg-dark text-white',
}
