import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import Header from "./Header/index";
import SideBar from "containers/SideBar/index";
import Footer from "components/Footer";
import { isIOS, isMobile } from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {getProfile} from "../../../redux/actions/Auth";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {hideMessage} from "../../../redux/actions";

const Vertical =(props)=> {
    const dispatch = useDispatch();
    const {alertMessage, showMessage} = useSelector(({commonData}) => commonData)

    useEffect(() => {
        dispatch(getProfile());
    },[]);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 200);
        }

    }, [showMessage]);

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    const [navCollapsed, setNavCollapsed] = React.useState(false)

    const onToggleCollapsedNav = (e) => {
        setNavCollapsed(!navCollapsed)
    };

    return (
      <div className={`app-container fixed-drawer`}>

        <SideBar navCollapsed={navCollapsed} onToggleCollapsedNav={onToggleCollapsedNav}/>
        <div className="app-main-container">
          <div className="app-header">
            <Header onToggleCollapsedNav={onToggleCollapsedNav}/>
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              {props.children}
            </div>
            <Footer/>
          </main>
        </div>
        {/*<ColorOption/>*/}
          {showMessage && NotificationManager.error(alertMessage)}
          <NotificationContainer/>
      </div>
    );
  };

export default withRouter(Vertical);
