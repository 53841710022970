import axiosInstance from "../axiosApi";
import { ROUTE_NAME, routeTo } from './routes';
import {objectToFormData} from "../function/objectToFormData";

export default {
    getProperties: async (filters) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.PROPERTY.PROPERTIES, filters)).then(response => {
            return response
        }).catch(error => error)
    },
    createProperty: async (data) => {
        return await axiosInstance.post(routeTo(ROUTE_NAME.PROPERTY.PROPERTY_CREATE), data).then(response => {
            return response
        }).catch(error => error)
    },
    updateProperty: async ({data, id, type}) => {
        let config = {}
        if(!type){
            data = objectToFormData(data)
            config = {headers: { 'content-type': 'multipart/form-data' }}
        }
        return await axiosInstance.patch(routeTo(ROUTE_NAME.PROPERTY.PROPERTY_UPDATE, {}, {id}), data, config).then(response => {
            return response
        }).catch(error => error)
    },

    detailProperty: async ({id}) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.PROPERTY.PROPERTY_DETAIL, {}, {id})).then(response => {
            return response
        }).catch(error => error)
    },
};
