import React from "react";
import {Grid, TextField, Typography} from '@material-ui/core'
import {errorDJRestToString} from "util/function/errorsProcess";


const ChangePasswordForm = ({register, initState = {}, errors = {}}) => {


    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom={true}>Mật khẩu cũ</Typography>
                <TextField  type='password' inputRef={register} name="old_password" fullWidth error={!!errors.old_password} helperText={errorDJRestToString(errors.old_password)} defaultValue={initState.old_password}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom={true}>Mật khẩu mới</Typography>
                <TextField  inputRef={register} name="new_password" fullWidth error={!!errors.new_password} helperText={errorDJRestToString(errors.new_password)} defaultValue={initState.new_password}/>
            </Grid>


        </Grid>
    )
}

export default ChangePasswordForm
