import React, {useEffect} from "react";
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from '@material-ui/core/styles';
// import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Redirect, Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {IntlProvider} from "react-intl";
import "assets/vendors/style";
import purpleTheme from "./themes/purpleTheme";
import AppLocale from "../lngProvider";
import SignIn from "./SignIn";
import {setInitUrl} from "../redux/actions/Auth";
import asyncComponent from "util/asyncComponent";
import AppLayout from "./AppLayout";
import {DEEP_PURPLE} from "../constants/ThemeColors";
import moment from "moment";
import Pages from "../app/routes/extraPages";


const RestrictedRoute = ({component: Component, token, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            token
                ? <Component {...props} />
                : <Redirect
                    to={{
                      pathname: '/signin',
                      state: {from: props.location}
                    }}
                />}
    />;

const App = (props) => {
  const dispatch = useDispatch();
  const {locale} = useSelector(({settings}) => settings);
  const {token, initURL} = useSelector(({auth}) => auth);
  const {match, location} = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);


  let applyTheme = createMuiTheme(purpleTheme);
  document.body.classList.add(DEEP_PURPLE);
  // dispatch(setThemeColor(DEEP_PURPLE))
  if (location.pathname === '/') {
    if (token === null) {
      return ( <Redirect to={'/signin'}/> );
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      return ( <Redirect to={'/app/property'}/> );
    } else {
      return ( <Redirect to={initURL}/> );
    }
  }


  const currentAppLocale = AppLocale[locale.locale];
  return (
      <ThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
          <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}>
            <div className="app-main">
              <Switch>
                <RestrictedRoute path={`${match.url}app`} token={token}
                                 component={AppLayout}/>
                <Route path='/signin' component={SignIn}/>
                <Route path='/page' component={Pages}/>
                {/*<Route path='/signup' component={SignUp}/>*/}
                <Route
                    component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
              </Switch>
            </div>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
  );
};

export default App;
