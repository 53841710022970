import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Customer from './Customer';
import Responsive from './Responsive';
import ProjectDeveloper from './ProjectDeveloper';
import Property from './Property';
import Address from './Address';
import PropertyID from './PropertyID';
import Image from './Image';
import PropertyItem from './PropertyItem';


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  customerData : Customer,
  responsive : Responsive,
  projectDeveloper : ProjectDeveloper,
  propertyData : Property,
  addressData : Address,
  propertyIDData : PropertyID,
  imageData : Image,
  propertyItemData : PropertyItem,
});
