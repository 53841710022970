import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";


const CustomerApps = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}/:id`} component={asyncComponent(() => import('./customerDetail'))}/>
            <Route path={`${match.url}`} component={asyncComponent(() => import("./CustomersV2"))}/>
            <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>
        </Switch>
    </div>
);

export default CustomerApps;
