import {
    GET_PROPERTY_ID_PROJECTS,
    GET_PROPERTY_ID_PROJECTS_SUCCESS,
    DETAIL_PROPERTY_ID_PROJECT,
    DETAIL_PROPERTY_ID_PROJECT_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
    projects: [],
    projectsLoading: true,
    detail: {}

};


export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_PROPERTY_ID_PROJECTS: {
            return {
                ...state,
                projects: [],
                projectsLoading: true
            }
        }
        case GET_PROPERTY_ID_PROJECTS_SUCCESS: {
            return {
                ...state,
                projects: action.payload,
                projectsLoading: false
            }
        }
        case DETAIL_PROPERTY_ID_PROJECT:{
            return {
                ...state,
                detail: {}
            }
        }
        case DETAIL_PROPERTY_ID_PROJECT_SUCCESS: {
            return {
                ...state,
                detail: action.payload
            }
        }
        default:
            return state;
    }
}
