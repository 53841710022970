export const objectToFormData = (obj) => {
    const formData = new FormData();
    for(let key in obj){
        if (Array.isArray(obj[key])){
            obj[key].forEach(value => formData.append(key, value))
        }else{
            formData.append(key, obj[key]);
        }
    }
    return formData
}

export const objectToJsonData = (obj) => {
    let data = Object.assign({}, obj)
    for(let key in data){
        if(data[key] === 'null'){
            data[key] = null;
        }
    }
    return data
}
