import React from 'react';
import {
    checkConfirmPaymentOrderGroup,
    checkConfirmPropertyGroup,
    checkUpdateCustomerGroup,
    checkUpdateTransactionOrderGroup
} from "../../util/function/checkGroup";
import {STATUS_PROPERTY_DICT} from "../../constants/statusDict/property";
import {CUSTOMER_STATUS_DICT} from "../../constants/statusDict/customer";
import {TRANSACTION_ORDER_STATUS_DICT} from "../../constants/statusDict/transactionOrder";
import {PAYMENT_ORDER_STATUS_DICT} from "../../constants/statusDict/paymentOrder";
import {RE_VERIFY_STATUS_DICT} from "../../constants/statusDict/reVerify";

const groupSchema = {
    property_confirm: checkConfirmPropertyGroup,
    customer_confirm: checkUpdateCustomerGroup,
    customer_lock: checkUpdateCustomerGroup,
    customer_unlock: checkUpdateCustomerGroup,
    customer_close: checkUpdateCustomerGroup,
    customer_reverify_confirm: checkUpdateCustomerGroup,
    transaction_order_confirm: checkUpdateTransactionOrderGroup,
    payment_order_confirm: checkConfirmPaymentOrderGroup,
}

const iconSchema = {
    property_confirm: 'city-alt',
    customer_confirm: 'account-box',
    customer_lock: 'account-box',
    customer_unlock: 'account-box',
    customer_close: 'account-box',
    customer_reverify_confirm: 'account-box',
    transaction_order_confirm: 'code-setting',
    payment_order_confirm: 'money-box',
}

const backGroundSchema = {
    property_confirm: 'bg-pink',
    customer_confirm: 'bg-blue',
    customer_lock: 'bg-blue',
    customer_unlock: 'bg-blue',
    customer_close: 'bg-blue',
    customer_reverify_confirm: 'bg-blue',
    transaction_order_confirm: 'bg-green',
    payment_order_confirm: 'bg-primary',
}

const titleSchema = {
    property_confirm: (count) => `Có ${count} sản phẩm đầu tư đang chờ duyệt`,
    customer_confirm: (count) => `Có ${count} khách hàng đang chờ xác thực`,
    customer_lock: (count) => `Có ${count} khách hàng yêu cầu khóa tài khoản`,
    customer_unlock: (count) => `Có ${count} khách hàng yêu cầu mở khóa tài khoản`,
    customer_close: (count) => `Có ${count} khách hàng yêu cầu đóng tài khoản`,
    customer_reverify_confirm: (count) => `Có ${count} khách hàng yêu cầu xác thực lại tài khoản`,
    transaction_order_confirm:(count) => `Có ${count} lệnh nạp rút tiền đang chờ duyệt`,
    payment_order_confirm:(count) => `Có ${count} lệnh thanh toán đang chờ duyệt`,
}

const RedirectSchema = {
    property_confirm: `/app/property?status=${STATUS_PROPERTY_DICT.AWAITING_APPROVAL}`,
    customer_confirm: `/app/customer?verify_status=${CUSTOMER_STATUS_DICT.WAIT_TO_VERIFY}`,
    customer_lock: `/app/customer?verify_status=${CUSTOMER_STATUS_DICT.WAIT_TO_LOCK}`,
    customer_unlock: `/app/customer?verify_status=${CUSTOMER_STATUS_DICT.WAIT_TO_UNLOCK}`,
    customer_close: `/app/customer?verify_status=${CUSTOMER_STATUS_DICT.WAIT_TO_CLOSE}`,
    customer_reverify_confirm: `/app/re-verify?verify_status=${RE_VERIFY_STATUS_DICT.WAIT_TO_VERIFY}`,
    transaction_order_confirm: `/app/transaction-order?status=${TRANSACTION_ORDER_STATUS_DICT.IN_PROCESS}`,
    payment_order_confirm: `/app/payment-order?status=${PAYMENT_ORDER_STATUS_DICT.IN_PROGRESS}`,
}


export const notificationDataDisplay = (dataDict ={}, groups) => {
    const data = []
    Object.keys(dataDict).forEach(value => {
        if(value in groupSchema && groupSchema[value](groups) && dataDict[value]['count']>0){
            data.push({
                icon: <i className={'zmdi  zmdi-' + iconSchema[value]}/>,
                title: titleSchema[value](dataDict[value]['count']),
                backGround: backGroundSchema[value],
                redirectLink: RedirectSchema[value]
            })
        }
    })
    return data
}
