import React from "react";
import { Route, Switch } from "react-router-dom";
import CustomerApps from "./customer";
import PropertyApps from "./property";
import ProjectDeveloperApps from "./projectDeveloper";
import employeeApp from "./employeeApp";
import bankAccountApp from "./bankAccountApp";
import transactionOrderApp from "./transactionOrderApp";
import transactionApp from "./transactionApp";
import tradeApp from "./tradeApp";
import offerApp from "./offerApp";
import portfolioApp from "./portfolioApp";
import paymentOrderApp from "./paymentOrderApp";
import pollApp from "./pollApp";
import asyncComponent from "util/asyncComponent";
import { withRouter } from "react-router";
import LogEntryApp from "./logEntryApp";
import ProductOwnerApp from "./productOwner";
import ReVerifyApp from "./reVerifyCustomer";

const Routes = ({ match }) =>
  <Switch>
    <Route path={`${match.url}/customer`} component={CustomerApps}/>
    <Route path={`${match.url}/property`} component={PropertyApps}/>
    <Route path={`${match.url}/project-developer`} component={ProjectDeveloperApps}/>
    <Route path={`${match.url}/employee`} component={employeeApp}/>
    <Route path={`${match.url}/bank-account`} component={bankAccountApp}/>
    <Route path={`${match.url}/transaction-order`} component={transactionOrderApp}/>
    <Route path={`${match.url}/transaction`} component={transactionApp}/>
    <Route path={`${match.url}/trade`} component={tradeApp}/>
    <Route path={`${match.url}/offer`} component={offerApp}/>
    <Route path={`${match.url}/portfolio`} component={portfolioApp}/>
    <Route path={`${match.url}/payment-order`} component={paymentOrderApp}/>
    <Route path={`${match.url}/poll`} component={pollApp}/>
    <Route path={`${match.url}/log-entry`} component={LogEntryApp}/>
    <Route path={`${match.url}/product-owner`} component={ProductOwnerApp}/>
    <Route path={`${match.url}/re-verify`} component={ReVerifyApp}/>
    <Route path={`${match.url}/403`} component={asyncComponent(() => import("app/routes/extraPages/routes/403"))}/>
    <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>
  </Switch>;


export default withRouter(Routes);
