import React from "react";

export const CircularProgress = ({className}) => <div className={`loader ${className}`}>
  <svg className="circular" viewBox="25 25 50 50">
    <circle className="path" cx="50" cy="50" r="15" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
  </svg>
</div>;

export const CircularProgressCenter = () => {
  return(
      <div className="loader-view"
           >
        <CircularProgress/>
      </div>
  )
}
