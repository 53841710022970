import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import {
  checkViewBankAccountGroup,
  checkViewCustomerGroup,
  checkViewEmployeeGroup, checkViewLogEntryGroup,
  checkViewOfferGroup,
  checkViewPaymentOrderGroup,
  checkViewPollGroup,
  checkViewPropertyGroup,
  checkViewSection1,
  checkViewSection2,
  checkViewSection3,
  checkViewSection4,
  checkViewTradeGroup,
  checkViewTransactionGroup,
  checkViewTransactionOrderGroup
} from "../../util/function/checkGroup";

const SideBarContent = () => {
  const navigationMenus = [
    // {
    //   name: 'account',
    //   type: 'section',
    //   children: [
    //     {
    //       name: 'account',
    //       icon: 'account-box',
    //       type: 'collapse',
    //       children: [
    //         {
    //           name: 'account.customer',
    //           type: 'item',
    //           link: '/app/customer'
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
      name: 'project',
      checkGroup: checkViewSection1,
      type: 'section',
      children: [
        {
          name: 'project_developer',
          checkGroup: checkViewPropertyGroup,
          type: 'item',
          icon: 'account-circle',
          link: '/app/project-developer'
        },
        {
          name: 'product_owner',
          checkGroup: checkViewPropertyGroup,
          type: 'item',
          icon: 'accounts-list',
          link: '/app/product-owner'
        },
        {
          name: 'property',
          checkGroup: checkViewPropertyGroup,
          type: 'item',
          icon: 'city-alt',
          link: '/app/property'
        }
      ]
    },

    {
      name: 'account',
      checkGroup: checkViewSection2,
      type: 'section',
      children: [
        {
          name: 'account.customer',
          checkGroup: checkViewCustomerGroup,
          type: 'item',
          icon: 'account-box',
          link: '/app/customer'
        },
        {
          name: 'employee',
          checkGroup: checkViewEmployeeGroup,
          type: 'item',
          icon: 'account-box-mail',
          link: '/app/employee'
        },
        {
          name: 're_verify',
          checkGroup: checkViewCustomerGroup,
          type: 'item',
          icon: 'assignment-check',
          link: '/app/re-verify'
        }
      ]
    },
    {
      name: 'investment',
      checkGroup: checkViewSection3,
      type: 'section',
      children: [

        {
          name: 'transaction_order',
          checkGroup: checkViewTransactionOrderGroup,
          type: 'item',
          icon: 'code-setting',
          link: '/app/transaction-order'
        },
        {
          name: 'trade',
          checkGroup: checkViewTradeGroup,
          type: 'item',
          icon: 'hourglass-alt',
          link: '/app/trade'
        },
        {
          name: 'offer',
          checkGroup: checkViewOfferGroup,
          type: 'item',
          icon: 'shopping-cart',
          link: '/app/offer'
        },
        {
          name: 'portfolio',
          checkGroup: checkViewCustomerGroup,
          type: 'item',
          icon: 'tv-list',
          link: '/app/portfolio'
        },
        {
          name: 'transaction',
          checkGroup: checkViewTransactionGroup,
          type: 'item',
          icon: 'repeat',
          link: '/app/transaction'
        },
      ]
    },

    {
      name: 'system',
      checkGroup: checkViewSection4,
      type: 'section',
      children: [
        {
          name: 'bank_account',
          checkGroup: checkViewBankAccountGroup,
          type: 'item',
          icon: 'balance',
          link: '/app/bank-account'
        },
        {
          name: 'payment_order',
          checkGroup: checkViewPaymentOrderGroup,
          type: 'item',
          icon: 'money-box',
          link: '/app/payment-order'
        },
        {
          name: 'poll',
          checkGroup: checkViewPollGroup,
          type: 'item',
          icon: 'thumb-up',
          link: '/app/poll'
        },
        {
          name: 'log-entry',
          checkGroup: checkViewLogEntryGroup,
          type: 'item',
          icon: 'card-sim',
          link: '/app/log-entry'
        },
      ]
    },

  ];

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus}/>
    </CustomScrollbars>
  );
};

export default SideBarContent;
