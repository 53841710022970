import {
    GET_PROVINCES,
    GET_PROVINCES_SUCCESS,
    GET_DISTRICTS,
    GET_DISTRICTS_SUCCESS,
    GET_WARDS,
    GET_WARDS_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    provinces: [],
    districts: [],
    wards: []

};


export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_PROVINCES: {
            return {
                ...INIT_STATE
            }
        }
        case GET_PROVINCES_SUCCESS: {
            return {
                ...state,
                provinces: action.payload
            }
        }
        case GET_DISTRICTS: {
            return {
                ...state,
                districts: []
            }
        }
        case GET_DISTRICTS_SUCCESS: {
            return {
                ...state,
                districts: action.payload
            }
        }
        case GET_WARDS: {
            return {
                ...state,
                wards: []
            }
        }
        case GET_WARDS_SUCCESS: {
            return {
                ...state,
                wards: action.payload
            }
        }

        default:
            return state;
    }
}
