import {
    WINDOW_WIDTH,
    TOGGLE_COLLAPSED_NAV
} from '../../constants/ActionTypes';

export function updateWindowWidth(width) {
    return {type: WINDOW_WIDTH, width};
}

export function toggleCollapsedNav(isNavCollapsed) {
    return {type: TOGGLE_COLLAPSED_NAV, isNavCollapsed};
}
