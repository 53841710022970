import deepPurple from '@material-ui/core/colors/deepPurple';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import createPalette from '@material-ui/core/styles/createPalette';

export default {
  palette: createPalette({
    primary: {
      light: deepPurple[300],
      main: deepPurple[500],
      dark: deepPurple[700],
      contrastText: '#fff'
    },
    secondary: {
      light: amber[300],
      main: amber['A700'],
      dark: amber[700],
      contrastText: '#fff'
    },
    info: {
      light: blue[300],
      main: blue['A700'],
      dark: blue[700],
      contrastText: '#fff'
    }
  }),
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
  overrides: {
    MuiSelect: { icon: { position: 'relative', }, },
    MuiDialogContent: { root: { overflowY: 'initial', }, },
    MuiOutlinedInput: {adornedEnd: {'padding-right': '6px'}, adornedStart:{'padding-left': '6px'}},
    MuiInputAdornment: {positionEnd: {'margin-left': '2px'} }
  }
};
