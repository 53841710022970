import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {NavLink} from "react-router-dom";


const NotificationItem = ({notification, onAppNotificationSelect}) => {

  const {icon, title, backGround, redirectLink} = notification;
  return (
    <li className="media" onClick={onAppNotificationSelect}>
        <NavLink to={redirectLink} className="media">
            <Avatar
                className={`mr-2 ${backGround}`}
            >
                {icon}
            </Avatar>
            <div className="media-body align-self-center">
                <p className=" mb-0">{title}</p>
                {/*<Button size="small" className="jr-btn jr-btn-xs mb-0"><i*/}
                {/*  className={`zmdi ${icon} zmdi-hc-fw`}/></Button> <span className="meta-date"><small>{time}</small></span>*/}
            </div>
        </NavLink>
    </li>
  );
};

export default NotificationItem;
