import {
    GET_PROPERTY_ID_PROJECTS,
    GET_PROPERTY_ID_PROJECTS_SUCCESS,
    DETAIL_PROPERTY_ID_PROJECT,
    DETAIL_PROPERTY_ID_PROJECT_SUCCESS
} from "../../constants/ActionTypes";

export const getPropertyIDProjects = (payload) => {
    return {
        type: GET_PROPERTY_ID_PROJECTS,
        payload: payload
    }
};

export const getPropertyIDProjectsSuccess = (payload) => {
    return {
        type: GET_PROPERTY_ID_PROJECTS_SUCCESS,
        payload: payload
    }
};

export const detailPropertyIDProject = (payload) => {
    return {
        type: DETAIL_PROPERTY_ID_PROJECT,
        payload: payload
    }
};

export const detailPropertyIDProjectSuccess = (payload) => {
    return {
        type: DETAIL_PROPERTY_ID_PROJECT_SUCCESS,
        payload: payload
    }
};





