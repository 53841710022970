import {
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMER_DETAIL,
    GET_CUSTOMERS_DETAIL_SUCCESS,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_BAD, OPEN_CUSTOMER_APPROVE_DIALOG
} from "../../constants/ActionTypes";

export const getCustomer = (payload) => {
    return {
        type: GET_CUSTOMERS,
        payload: payload
    }
};

export const getCustomerSuccess = (payload) => {
    return {
        type: GET_CUSTOMERS_SUCCESS,
        payload: payload
    }
};

export const getCustomerDetail = (payload) => {
    return {
        type: GET_CUSTOMER_DETAIL,
        payload: payload
    };
};

export const getCustomerDetailSuccess = (payload) => {
    return {
        type: GET_CUSTOMERS_DETAIL_SUCCESS,
        payload: payload
    };
};

export const updateCustomer = (payload) => {
    return {
        type: UPDATE_CUSTOMER,
        payload: payload
    };
};

export const updateCustomerSuccess = (payload) => {
    return {
        type: UPDATE_CUSTOMER_SUCCESS,
        payload: payload
    };
};

export const updateCustomerBad = (payload) => {
    return {
        type: UPDATE_CUSTOMER_BAD,
        payload: payload
    };
};

export const openCustomerApprovalDialog = (payload) => {
    return {
        type: OPEN_CUSTOMER_APPROVE_DIALOG,
        payload: payload
    };
};






