import {GROUP_DICT} from "../../constants/statusDict/employee";

const checkGroup = (groupsCheck, groupsCurrent) => {
    return groupsCurrent.some(r => groupsCheck.indexOf(r) >= 0);
}

export const checkViewPropertyGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_PROPERTY, GROUP_DICT.CONFIRM_PROPERTY, GROUP_DICT.UPDATE_PROPERTY, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkUpdatePropertyGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.CONFIRM_PROPERTY, GROUP_DICT.UPDATE_PROPERTY, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkConfirmPropertyGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.CONFIRM_PROPERTY, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkViewCustomerGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_CUSTOMER, GROUP_DICT.UPDATE_CUSTOMER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkUpdateCustomerGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.UPDATE_CUSTOMER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkViewEmployeeGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_EMPLOYEE, GROUP_DICT.UPDATE_EMPLOYEE, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkUpdateEmployeeGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.UPDATE_EMPLOYEE, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkViewTransactionGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_TRANSACTION, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkViewTransactionOrderGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_TRANSACTION_ORDER, GROUP_DICT.UPDATE_TRANSACTION_ORDER, GROUP_DICT.ADMIN, GROUP_DICT.UPDATE_EMPLOYEE_TRANSACTION_ORDER, GROUP_DICT.CREATE_TRANSACTION_ORDER]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkUpdateTransactionOrderGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.UPDATE_TRANSACTION_ORDER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}
export const checkUpdateEmployeeTransactionOrderGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.UPDATE_EMPLOYEE_TRANSACTION_ORDER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkCreateTransactionOrderGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.CREATE_TRANSACTION_ORDER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkViewBankAccountGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_BANK_ACCOUNT, GROUP_DICT.UPDATE_BANK_ACCOUNT, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkUpdateBankAccountGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.UPDATE_BANK_ACCOUNT, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkViewOfferGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_OFFER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkViewTradeGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_OFFER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkViewPaymentOrderGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_PAYMENT_ORDER, GROUP_DICT.CONFIRM_PAYMENT_ORDER, GROUP_DICT.UPDATE_PAYMENT_ORDER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkUpdatePaymentOrderGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.CONFIRM_PAYMENT_ORDER, GROUP_DICT.UPDATE_PAYMENT_ORDER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkConfirmPaymentOrderGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.CONFIRM_PAYMENT_ORDER, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkViewPollGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.VIEW_POLL, GROUP_DICT.UPDATE_POLL, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkUpdatePollGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.UPDATE_POLL, GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}


export const checkViewLogEntryGroup = (groupsCurrent) => {
    const groupCheckList = [GROUP_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

// -----------------------------------------------------------------------------------------------------

export const checkViewSection1 = (groupsCurrent) => {
    return checkViewPropertyGroup(groupsCurrent) || checkViewPropertyGroup(groupsCurrent)
}

export const checkViewSection2 = (groupsCurrent) => {
    return checkViewCustomerGroup(groupsCurrent) || checkViewEmployeeGroup(groupsCurrent)
}

export const checkViewSection3 = (groupsCurrent) => {
    return checkViewTransactionOrderGroup(groupsCurrent) || checkViewTradeGroup(groupsCurrent) || checkViewOfferGroup(groupsCurrent) || checkViewCustomerGroup(groupsCurrent) || checkViewTransactionGroup(groupsCurrent)
}

export const checkViewSection4 = (groupsCurrent) => {
    return checkViewBankAccountGroup(groupsCurrent) || checkViewPaymentOrderGroup(groupsCurrent) || checkViewPollGroup(groupsCurrent) || checkViewLogEntryGroup(groupsCurrent)
}
