import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import propertyService from "../../util/services/propertyApi";
import {
    GET_PROPERTIES,
    CREATE_PROPERTY,
    DETAIL_PROPERTY,
    UPDATE_PROPERTY
} from "constants/ActionTypes";
import {
    getPropertiesSuccess,
    createPropertyBad,
    detailPropertySuccess,
    updatePropertyBad, updatePropertySuccess, detailProperty

} from "redux/actions/Property";
import {showMessage} from "redux/actions/Common"
import {history} from "../../util/function/history";


function* GetProjectDeveloper(actions) {
    try {
        const response = yield call(propertyService.getProperties, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getPropertiesSuccess(response.data));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function* DetailProperty(actions) {
    try {
        const response = yield call(propertyService.detailProperty, actions.payload);
        if (response.message) {
            if (response.status === 404){
                history.push('/app/property')
            }else{
                yield put(showMessage(response.message));
            }
        } else {
            yield put(detailPropertySuccess(response.data));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


function* CreateProperty(actions) {
    try {
        const developer = yield call(propertyService.createProperty, actions.payload);
        if (developer.message) {
            if(developer.response.status === 400){
                yield put(createPropertyBad(developer.response.data));
            }
            else{
                yield put(showMessage(developer.message));
            }
        } else {
            history.push(window.location.pathname + window.location.search)
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


function* UpdateProperty(actions) {
    try {
        const response = yield call(propertyService.updateProperty, actions.payload);
        if (response.message) {
            if(response.response.status === 400){
                yield put(updatePropertyBad(response.response.data));
            }
            else{
                yield put(showMessage(response.message));
            }
        } else {
            yield put(updatePropertySuccess(response.data))
            yield put(detailProperty({id:actions.payload.id}))
            // history.push(window.location.pathname + window.location.search)
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

// ------------------------------------------------------------------

export function* watchGetProjectDevelopers() {
    yield takeEvery(GET_PROPERTIES, GetProjectDeveloper);
}

export function* watchCreateProperty() {
    yield takeEvery(CREATE_PROPERTY, CreateProperty);
}

export function* watchUpdateProperty() {
    yield takeEvery(UPDATE_PROPERTY, UpdateProperty);
}

export function* watchDetailProperty() {
    yield takeEvery(DETAIL_PROPERTY, DetailProperty);
}

//-------------------------------------------
export default function* rootSaga() {
    yield all([
        fork(watchGetProjectDevelopers),
        fork(watchCreateProperty),
        fork(watchUpdateProperty),
        fork(watchDetailProperty),
    ]);
}
