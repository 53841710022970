import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Service from "../../util/services/imageApi";

import {
    GET_PROPERTY_IMAGES,
    DELETE_PROPERTY_IMAGE,
    UPLOAD_PROPERTY_IMAGES
} from "constants/ActionTypes";

import {
    getPropertyImagesSuccess,
    getPropertyImages,
    uploadPropertyImagesSuccess,
    deletePropertyImageSuccess, openUploadImageDialog

} from "redux/actions/Image";

import {showMessage} from "redux/actions/Common"


function* GetPropertyImages(actions) {
    try {
        const response = yield call(Service.getPropertyImages, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getPropertyImagesSuccess(response.data.results));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function* UploadPropertyImages(actions) {
    try {
        const response = yield call(Service.uploadPropertyImages, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getPropertyImages({id:actions.payload.id}))
            yield put(openUploadImageDialog(false))
            // history.push(window.location.pathname + window.location.search)
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


function* DeletePropertyImage(actions) {
    try {
        const response = yield call(Service.deleteImage, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            // history.push(window.location.pathname + window.location.search)
            yield put(getPropertyImages({id:actions.payload.propertyId}))
            yield put(deletePropertyImageSuccess())
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

// ------------------------------------------------------------------

export function* watchGetPropertyImages() {
    yield takeEvery(GET_PROPERTY_IMAGES, GetPropertyImages);
}

export function* watchUploadPropertyImage() {
    yield takeEvery(UPLOAD_PROPERTY_IMAGES, UploadPropertyImages);
}

export function* watchDeletePropertyImage() {
    yield takeEvery(DELETE_PROPERTY_IMAGE, DeletePropertyImage);
}

// export function* watchDetailProperty() {
//     yield takeEvery(DETAIL_PROPERTY, DetailProperty);
// }

//-------------------------------------------
export default function* rootSaga() {
    yield all([
        fork(watchGetPropertyImages),
        fork(watchUploadPropertyImage),
        fork(watchDeletePropertyImage),
        // fork(watchDetailProperty),
    ]);
}
