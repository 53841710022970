import React from "react";

export const RE_VERIFY_STATUS_DICT = {
    WAIT_TO_VERIFY:0,
    VERIFIED: 1,
    REJECTED: 2
}

export const RE_VERIFY_DISPLAY_STATUS_DICT = {
    [RE_VERIFY_STATUS_DICT.WAIT_TO_VERIFY]: 'Chờ xác thực',
    [RE_VERIFY_STATUS_DICT.VERIFIED]: 'Đã xác thực',
    [RE_VERIFY_STATUS_DICT.REJECTED]: 'Đã từ chối',
}



export const RE_VERIFY_STYLE_STATUS_DICT = {
    [RE_VERIFY_STATUS_DICT.WAIT_TO_VERIFY]: 'bg-warning',
    [RE_VERIFY_STATUS_DICT.VERIFIED]: 'bg-primary text-white',
    [RE_VERIFY_STATUS_DICT.REJECTED]: 'bg-dark text-white',
}



export const RE_VERIFY_TYPE_CARD_DICT = {
    ID_CARD:0,
    PASSPORT: 1,
}

export const RE_VERIFY_TYPE_CARD_DISPLAY_DICT = {
    [RE_VERIFY_TYPE_CARD_DICT.ID_CARD]: 'CMND/CCCD',
    [RE_VERIFY_TYPE_CARD_DICT.PASSPORT]: 'PASSPORT',
}

