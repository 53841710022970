const languageData = [
  {
    languageId: 'vietnam',
    locale: 'vi',
    name: 'Tiếng Việt',
    icon: 'vn'
  },


];
export default languageData;
