import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import account from "../../util/services/accountApi";
import {GET_CUSTOMER_DETAIL, GET_CUSTOMERS, UPDATE_CUSTOMER} from "constants/ActionTypes";
import {
    getCustomerDetail,
    getCustomerDetailSuccess,
    getCustomerSuccess,
    updateCustomerBad,
    updateCustomerSuccess
} from "redux/actions/Customer";
import {showMessage} from "redux/actions/Common"
import {Update} from "@material-ui/icons";


function *GetCustomers(actions) {
    try {
        const customers = yield call(account.getCustomers, actions.payload);
        if (customers.message) {
            yield put(showMessage(customers.message));
        } else {
            yield put(getCustomerSuccess(customers.data));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function *GetCustomerDetail(actions) {
    try {
        const customer = yield call(account.getCustomerDetail, actions.payload);
        if (customer.message) {
            yield put(showMessage(customer.message));
        } else {
            yield put(getCustomerDetailSuccess(customer.data));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


function* UpdateCustomer(actions) {
    try {
        const response = yield call(account.updateCustomer, actions.payload);
        if (response.message) {
            if(response.response.status === 400){
                yield put(updateCustomerBad(response.response.data));
            }
            else{
                yield put(showMessage(response.message));
            }
        } else {
            yield put(updateCustomerSuccess(response.data))
            yield put(getCustomerDetail({id:actions.payload.id}))
            // history.push(window.location.pathname + window.location.search)
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}
// ------------------------------------------------------------------

export function* watchGetCustomers() {
    yield takeEvery(GET_CUSTOMERS, GetCustomers);
}
export function* watchGetCustomerDetail() {
    yield takeEvery(GET_CUSTOMER_DETAIL, GetCustomerDetail);
}
export function* watchUpdateCustomer() {
    yield takeEvery(UPDATE_CUSTOMER, UpdateCustomer);
}
//-------------------------------------------
export default function* rootSaga() {
    yield all([
        fork(watchGetCustomers),
        fork(watchGetCustomerDetail),
        fork(watchUpdateCustomer),
    ]);
}
