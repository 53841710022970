import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar'
import {useDispatch, useSelector} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {getProfile, userSignOut} from 'redux/actions/Auth';
import FormDialog from "../Dialog/FormDialog";
import authApi from "../../util/services/authApi";
import {showMessage} from "../../redux/actions";
import ChangePasswordForm from "../Forms/EmployeeForm/ChangePasswordForm";
import UploadImagesDialog from "../Dialog/UploadImagesDialog";

const UserInfo = () => {

    const dispatch = useDispatch();
    const [anchorE1, setAnchorE1] = useState(null);
    const [open, setOpen] = useState(false);
    const {profile} = useSelector(({auth}) => auth);

    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const [openUpload, setOpenUpload] = React.useState(false);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({})

    const handleClick = event => {
        setOpen(true);
        setAnchorE1(event.currentTarget);
    };

    const handleRequestClose = () => {
        setOpen(false);
    };
    const fetchChangePassword = async (data) =>{
        setSaving(true)
        const response =  await authApi.changePassword(data)
        if (response.message) {
            if(response.response.status === 400){
                setErrors(response.response.data)
                setSaving(false)
            }
            else{
                dispatch(showMessage(response.message))
            }
        } else {
            setOpenChangePassword(false)
            setErrors({});
            setSaving(false)
        }
    }

    const fetchUpdateAvatar = async (data) =>{
        setSaving(true)
        const response =  await authApi.updateProfile(data)
        if (response.message) {
            if(response.response.status === 400){
                setErrors(response.response.data)
                setSaving(false)
            }
            else{
                dispatch(showMessage(response.message))
            }
        } else {
            setOpenUpload(false);
            setErrors({});
            setSaving(false);
            dispatch(getProfile());
        }
    }


    const handleChangePasswordClose = (check, data) => {
        if(check){
            setSaving(true);
            fetchChangePassword(data);

        }else {
            setOpenChangePassword(false);
            setErrors({});
            setSaving(false)
        }
    };

    const handleUploadClose = (check, pictures) => {
        if (check && pictures.length > 0){
            fetchUpdateAvatar({image: pictures[0]})
        } else {
            setOpenUpload(false);
        }

    };
    return (
        <div className="user-profile d-flex flex-row align-items-center">
            <Avatar
                alt='...'
                src={profile.image_thumb}
                className="user-avatar "
            />
            <div className="user-detail">
                <h4 className="user-name d-flex" onClick={handleClick}><span
                    className='text-truncate'>{profile.fullname}</span> <i
                    className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
                </h4>
            </div>
            <Menu className="user-info"
                  id="simple-menu"
                  anchorEl={anchorE1}
                  open={open}
                  onClose={handleRequestClose}
                  PaperProps={{
                      style: {
                          minWidth: 120,
                          paddingTop: 0,
                          paddingBottom: 0
                      }
                  }}
            >
                <MenuItem onClick={()=>{
                    handleRequestClose();
                    setOpenUpload(true)
                }}>
                    <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
                    Đổi hình đại diện
                </MenuItem>
                <MenuItem onClick={()=>{
                    handleRequestClose();
                    setOpenChangePassword(true)
                }}>
                    <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
                    Đổi mật khẩu
                </MenuItem>
                <MenuItem onClick={() => {
                    handleRequestClose();
                    dispatch(userSignOut());
                }}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
                    Đăng xuất
                </MenuItem>
            </Menu>
            <FormDialog handleClose={handleChangePasswordClose} open={openChangePassword} title={'Đổi mật khẩu'}
                        form={ChangePasswordForm} maxWidth={'xs'} saving={saving} errors={errors}/>
            <UploadImagesDialog handleClose={handleUploadClose} open={openUpload} title={'Cập nhật hình đại diện'} single={true} saving={saving}/>
        </div>
    );
};

export default UserInfo;


