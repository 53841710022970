import React from "react";

export const PAYMENT_ORDER_TYPE_DICT = {
    FIXED_INTEREST: 0,
    FLEXIBLE_INTEREST: 1,
    INVESTMENT_REFUND: 2
}

export const PAYMENT_ORDER_TYPE_DISPLAY_DICT = {
    [PAYMENT_ORDER_TYPE_DICT.FIXED_INTEREST]: 'Lãi định kỳ',
    [PAYMENT_ORDER_TYPE_DICT.FLEXIBLE_INTEREST]: 'Lợi nhuận kinh doanh',
    [PAYMENT_ORDER_TYPE_DICT.INVESTMENT_REFUND]: 'Hoàn vốn',
}


export const PAYMENT_ORDER_TYPE_COLOR_DICT = {
    [PAYMENT_ORDER_TYPE_DICT.FIXED_INTEREST]: 'badge-primary',
    [PAYMENT_ORDER_TYPE_DICT.FLEXIBLE_INTEREST]: 'badge-secondary',
    [PAYMENT_ORDER_TYPE_DICT.INVESTMENT_REFUND]: 'badge-light',
}


export const PAYMENT_ORDER_STATUS_DICT = {
    IN_PROGRESS: 0,
    CONFIRMED: 1,
    DONE: 2,
    CANCELLED: 3
}

export const PAYMENT_ORDER_STATUS_DISPLAY_DICT = {
    [PAYMENT_ORDER_STATUS_DICT.IN_PROGRESS]: 'Chờ duyệt',
    [PAYMENT_ORDER_STATUS_DICT.CONFIRMED]: 'Đã duyệt',
    [PAYMENT_ORDER_STATUS_DICT.DONE]: 'Hoàn thành',
    [PAYMENT_ORDER_STATUS_DICT.CANCELLED]: 'Hủy bỏ',
}


export const PAYMENT_ORDER_STATUS_STYLE_DICT = {
    [PAYMENT_ORDER_STATUS_DICT.IN_PROGRESS]: 'badge-secondary badge-pill',
    [PAYMENT_ORDER_STATUS_DICT.CONFIRMED]: 'badge-info badge-pill',
    [PAYMENT_ORDER_STATUS_DICT.DONE]: 'badge-primary badge-pill',
    [PAYMENT_ORDER_STATUS_DICT.CANCELLED]: 'badge-dark badge-pill',
}
