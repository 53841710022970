import axiosInstance from "../axiosApi";
import { ROUTE_NAME, routeTo } from './routes';
import {objectToFormData} from "../function/objectToFormData";

export default {
    getProvinces: async (filters) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.ADDRESS.PROVINCES, filters)).then(response => {
            return response
        }).catch(error => error)
    },
    getDistricts: async (filters) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.ADDRESS.DISTRICTS, filters)).then(response => {
            return response
        }).catch(error => error)
    },
    getWards: async (filters) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.ADDRESS.WARDS, filters)).then(response => {
            return response
        }).catch(error => error)
    },
};
