import {
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS,
    GET_CUSTOMER_DETAIL,
    GET_CUSTOMERS_DETAIL_SUCCESS,
    UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER, UPDATE_CUSTOMER_BAD, OPEN_CUSTOMER_APPROVE_DIALOG
} from "constants/ActionTypes";

const INIT_STATE = {
    data: null,
    loader: true,
    detailLoading:true,
    detail: {},
    saving: false,
    openApproval: false

};


export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_CUSTOMERS: {
            return {
                ...state,
                loader: true
            }
        }
        case GET_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                loader: false
            }
        }
        case GET_CUSTOMER_DETAIL: {
            return {
                ...state,
                detailLoading: true,
                saving: false
            }
        }
        case GET_CUSTOMERS_DETAIL_SUCCESS: {
            return {
                ...state,
                detailLoading: false,
                detail: action.payload,
            }
        }
        case UPDATE_CUSTOMER: {
            return {
                ...state,
                saving: true
            }
        }
        case UPDATE_CUSTOMER_SUCCESS: {
            return {
                ...state,
                saving: false,
                openApproval: false
            }
        }
        case UPDATE_CUSTOMER_BAD: {
            return {
                ...state,
                saving: false
            }
        }
        case OPEN_CUSTOMER_APPROVE_DIALOG: {
            return {
                ...state,
                openApproval: action.payload,
                saving: action.payload?state.saving:action.payload
            }
        }
        default:
            return state;
    }
}
