import {
    GET_PROVINCES,
    GET_PROVINCES_SUCCESS,
    GET_DISTRICTS,
    GET_DISTRICTS_SUCCESS,
    GET_WARDS,
    GET_WARDS_SUCCESS,
} from "../../constants/ActionTypes";

export const getProvinces = (payload) => {
    return {
        type: GET_PROVINCES,
        payload: payload
    }
};

export const getProvincesSuccess = (payload) => {
    return {
        type: GET_PROVINCES_SUCCESS,
        payload: payload
    }
};

export const getDistricts = (payload) => {
    return {
        type: GET_DISTRICTS,
        payload: payload
    }
};

export const getDistrictsSuccess = (payload) => {
    return {
        type: GET_DISTRICTS_SUCCESS,
        payload: payload
    }
};

export const getWards = (payload) => {
    return {
        type: GET_WARDS,
        payload: payload
    }
};

export const getWardsSuccess = (payload) => {
    return {
        type: GET_WARDS_SUCCESS,
        payload: payload
    }
};





