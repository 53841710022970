import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import projectDeveloper from "../../util/services/projectDeveloperApi";
import {
    CREATE_PROJECT_DEVELOPERS,
    DETAIL_PROJECT_DEVELOPER, GET_AUTOCOMPLETE_PROJECT_DEVELOPERS,
    GET_PROJECT_DEVELOPERS,
    UPDATE_PROJECT_DEVELOPER
} from "constants/ActionTypes";
import {
    createProjectDeveloperBad,
    detailProjectDeveloperSuccess,
    getAutocompleteProjectDeveloperSuccess,
    getProjectDeveloperSuccess,
    updateProjectDeveloperBad
} from "redux/actions/ProjectDeveloper";
import {showMessage} from "redux/actions/Common"
import {history} from "../../util/function/history";


function* GetProjectDeveloper(actions) {
    try {
        const developers = yield call(projectDeveloper.getProjectDevelopers, actions.payload);
        if (developers.message) {
            yield put(showMessage(developers.message));
        } else {
            yield put(getProjectDeveloperSuccess(developers.data));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function* DetailProjectDeveloper(actions) {
    try {
        const developer = yield call(projectDeveloper.detailProjectDeveloper, actions.payload);
        if (developer.message) {
            yield put(showMessage(developer.message));
        } else {
            yield put(detailProjectDeveloperSuccess(developer.data));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


function* CreateProjectDeveloper(actions) {
    try {
        const developer = yield call(projectDeveloper.createProjectDevelopers, actions.payload);
        if (developer.message) {
            if(developer.response.status === 400){
                yield put(createProjectDeveloperBad(developer.response.data));
            }
            else{
                yield put(showMessage(developer.message));
            }
        } else {
            history.push(window.location.pathname + window.location.search)
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


function* UpdateProjectDeveloper(actions) {
    try {
        const developer = yield call(projectDeveloper.updateProjectDeveloper, actions.payload);
        if (developer.message) {
            if(developer.response.status === 400){
                yield put(updateProjectDeveloperBad(developer.response.data));
            }
            else{
                yield put(showMessage(developer.message));
            }
        } else {
            history.push(window.location.pathname + window.location.search)
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function* GetAutocompleteProjectDeveloper(actions) {
    try {
        const response = yield call(projectDeveloper.autocomplete, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getAutocompleteProjectDeveloperSuccess(response.data.results));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

// ------------------------------------------------------------------

export function* watchGetProjectDevelopers() {
    yield takeEvery(GET_PROJECT_DEVELOPERS, GetProjectDeveloper);
}

export function* watchCreateProjectDevelopers() {
    yield takeEvery(CREATE_PROJECT_DEVELOPERS, CreateProjectDeveloper);
}

export function* watchUpdateProjectDevelopers() {
    yield takeEvery(UPDATE_PROJECT_DEVELOPER, UpdateProjectDeveloper);
}

export function* watchDetailProjectDevelopers() {
    yield takeEvery(DETAIL_PROJECT_DEVELOPER, DetailProjectDeveloper);
}

export function* watchGetAutocompleteProjectDevelopers() {
    yield takeEvery(GET_AUTOCOMPLETE_PROJECT_DEVELOPERS, GetAutocompleteProjectDeveloper);
}

//-------------------------------------------
export default function* rootSaga() {
    yield all([
        fork(watchGetProjectDevelopers),
        fork(watchCreateProjectDevelopers),
        fork(watchUpdateProjectDevelopers),
        fork(watchDetailProjectDevelopers),
        fork(watchGetAutocompleteProjectDevelopers),
    ]);
}
