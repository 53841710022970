import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import addressService from "../../util/services/addressApi";
import {
    GET_PROVINCES,
    GET_DISTRICTS,
    GET_WARDS,
} from "constants/ActionTypes";
import {
    getDistrictsSuccess,
    getProvincesSuccess,
    getWardsSuccess
} from "redux/actions/Address";

import {showMessage} from "redux/actions/Common"


function* GetProvinces(actions) {
    try {
        const response = yield call(addressService.getProvinces, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getProvincesSuccess(response.data.results));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


function* GetDistricts(actions) {
    try {
        const response = yield call(addressService.getDistricts, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getDistrictsSuccess(response.data.results));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


function* GetWards(actions) {
    try {
        const response = yield call(addressService.getWards, actions.payload);
        if (response.message) {
            yield put(showMessage(response.message));
        } else {
            yield put(getWardsSuccess(response.data.results));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


// ------------------------------------------------------------------

export function* watchGetProjectDevelopers() {
    yield takeEvery(GET_PROVINCES, GetProvinces);
}

export function* watchGetDistricts() {
    yield takeEvery(GET_DISTRICTS, GetDistricts);
}

export function* watchGetWards() {
    yield takeEvery(GET_WARDS, GetWards);
}

//-------------------------------------------
export default function* rootSaga() {
    yield all([
        fork(watchGetProjectDevelopers),
        fork(watchGetDistricts),
        fork(watchGetWards),
    ]);
}
