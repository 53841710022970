import {
    GET_PROPERTY_ITEMS,
    GET_PROPERTY_ITEMS_SUCCESS,
    CREATE_PROPERTY_ITEM,
    CREATE_PROPERTY_ITEM_SUCCESS,
    UPDATE_PROPERTY_ITEM,
    UPDATE_PROPERTY_ITEM_SUCCESS,
    CANCEL_ITEM_ACTION,
    OPEN_ITEM_CREATE_DIALOG,
    OPEN_ITEM_UPDATE_DIALOG,
    DELETE_PROPERTY_ITEM,
    DELETE_PROPERTY_ITEM_SUCCESS, ITEM_BAD_REQUEST
} from "../../constants/ActionTypes";

export const getPropertyItems = ({property_id}) => {
    return {
        type: GET_PROPERTY_ITEMS,
        payload: {property_id}
    }
};

export const getPropertyItemsSuccess = (payload) => {
    return {
        type: GET_PROPERTY_ITEMS_SUCCESS,
        payload: payload
    }
};

export const createPropertyItem = (data) => {
    return {
        type: CREATE_PROPERTY_ITEM,
        payload: data
    }
};

export const createPropertyItemSuccess = (payload) => {
    return {
        type: CREATE_PROPERTY_ITEM_SUCCESS,
        payload: payload
    }
};
export const cancelItemAction = () => {
    return {
        type: CANCEL_ITEM_ACTION
    }
};

export const updatePropertyItem = (payload) => {
    return {
        type: UPDATE_PROPERTY_ITEM,
        payload: payload
    }
};

export const updatePropertyItemSuccess = (payload) => {
    return {
        type: UPDATE_PROPERTY_ITEM_SUCCESS,
        payload: payload
    }
};

export const openUpdateItemDialog = (payload) => {
    return {
        type: OPEN_ITEM_UPDATE_DIALOG,
        payload: payload
    }
};

export const openCreateItemDialog = (payload) => {
    return {
        type: OPEN_ITEM_CREATE_DIALOG,
        payload: payload
    }
};

export const deletePropertyItem = (payload) => {
    return {
        type: DELETE_PROPERTY_ITEM,
        payload: payload
    }
};

export const deletePropertyItemSuccess = (payload) => {
    return {
        type: DELETE_PROPERTY_ITEM_SUCCESS,
        payload: payload
    }
};

export const itemBadRequest = (payload) => {
    return {
        type: ITEM_BAD_REQUEST,
        payload: payload
    }
};




