import axiosInstance from "../axiosApi";
import { ROUTE_NAME, routeTo } from './routes';
import {objectToFormData} from "../function/objectToFormData";

export default {
    getPropertyImages: async ({id}) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.IMAGE.GET_PROPERTY_IMAGES, {}, {id})).then(response => {
            return response
        }).catch(error => error)
    },
    uploadPropertyImages: async ({data, id}) => {
        return await axiosInstance.post(routeTo(ROUTE_NAME.IMAGE.UPLOAD_PROPERTY_IMAGES, {}, {id}), objectToFormData(data), {headers: { 'content-type': 'multipart/form-data' }}).then(response => {
            return response
        }).catch(error => error)
    },
    // updateProperty: async ({data, id}) => {
    //     return await axiosInstance.patch(routeTo(ROUTE_NAME.PROPERTY.PROPERTY_UPDATE, {}, {id}), objectToFormData(data), {headers: { 'content-type': 'multipart/form-data' }}).then(response => {
    //         return response
    //     }).catch(error => error)
    // },
    //
    deleteImage: async ({id}) => {
        return await axiosInstance.delete(routeTo(ROUTE_NAME.IMAGE.DELETE_IMAGE, {}, {id})).then(response => {
            return response
        }).catch(error => error)
    },
};
