import {
    CANCEL_IMAGE_ACTION,
    DELETE_PROPERTY_IMAGE,
    DELETE_PROPERTY_IMAGE_SUCCESS,
    UPLOAD_PROPERTY_IMAGES_SUCCESS,
    UPLOAD_PROPERTY_IMAGES,
    GET_PROPERTY_IMAGES_SUCCESS,
    GET_PROPERTY_IMAGES, OPEN_IMAGE_DELETE_DIALOG, OPEN_IMAGE_UPLOAD_DIALOG
} from "constants/ActionTypes";

const INIT_STATE = {
    propertyImages: [],
    propertyImagesLoading: true,
    saving: false,
    openDelete: false,
    openUpload: false

};


export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_PROPERTY_IMAGES: {
            return {
                ...state,
                propertyImagesLoading: true
            }
        }
        case GET_PROPERTY_IMAGES_SUCCESS: {
            return {
                ...INIT_STATE,
                saving: false,
                propertyImages: action.payload,
                propertyImagesLoading: false
            }
        }
        case UPLOAD_PROPERTY_IMAGES: {
            return {
                ...state,
                saving: true
            }
        }
        case UPLOAD_PROPERTY_IMAGES_SUCCESS: {
            return {
                ...state,
                saving: false,
                openUpload: false
            }
        }
        case DELETE_PROPERTY_IMAGE: {
            return {
                ...state,
                saving: true,
            }
        }
        case DELETE_PROPERTY_IMAGE_SUCCESS: {
            return {
                ...state,
                saving: false,
                openDelete: false
            }
        }
        case CANCEL_IMAGE_ACTION: {
            return {
                ...state,
                saving: false
            }
        }
        case OPEN_IMAGE_DELETE_DIALOG: {
            return {
                ...state,
                openDelete: action.payload,
                saving: action.payload?state.saving:action.payload
            }
        }
        case OPEN_IMAGE_UPLOAD_DIALOG: {
            return {
                ...state,
                openUpload: action.payload,
                saving: action.payload?state.saving:action.payload
            }
        }
        default:
            return state;
    }
}
