import IntlMessages from "util/IntlMessages";
import React from "react";

export const CUSTOMER_STATUS_DICT = {
    REGISTER: 0,
    WAIT_TO_VERIFY:1,
    VERIFIED: 2,
    REJECTED: 3,
    WAIT_TO_LOCK: 4,
    LOCKED: 5,
    WAIT_TO_UNLOCK: 6,
    WAIT_TO_CLOSE: 7,
    CLOSED: 8,
}

export const CUSTOMER_DISPLAY_STATUS_DICT = {
    [CUSTOMER_STATUS_DICT.REGISTER]: <IntlMessages id="register"/>,
    [CUSTOMER_STATUS_DICT.WAIT_TO_VERIFY]: <IntlMessages id="wait to verify"/>,
    [CUSTOMER_STATUS_DICT.VERIFIED]: <IntlMessages id="verified"/>,
    [CUSTOMER_STATUS_DICT.REJECTED]: <IntlMessages id="rejected"/>,
    [CUSTOMER_STATUS_DICT.WAIT_TO_LOCK]: 'Chờ khóa',
    [CUSTOMER_STATUS_DICT.LOCKED]: 'Đã khóa',
    [CUSTOMER_STATUS_DICT.WAIT_TO_UNLOCK]: 'Chờ mở khóa',
    [CUSTOMER_STATUS_DICT.WAIT_TO_CLOSE]: 'Chờ đóng',
    [CUSTOMER_STATUS_DICT.CLOSED]: 'Đóng',
}



export const ACCOUNT_STYLE_STATUS_DICT = {
    [CUSTOMER_STATUS_DICT.REGISTER]: '',
    [CUSTOMER_STATUS_DICT.WAIT_TO_VERIFY]: 'bg-warning',
    [CUSTOMER_STATUS_DICT.VERIFIED]: 'bg-primary text-white',
    [CUSTOMER_STATUS_DICT.REJECTED]: 'bg-dark text-white',
    [CUSTOMER_STATUS_DICT.WAIT_TO_LOCK]: 'bg-warning',
    [CUSTOMER_STATUS_DICT.LOCKED]: 'bg-danger text-white',
    [CUSTOMER_STATUS_DICT.WAIT_TO_UNLOCK]: 'bg-warning',
    [CUSTOMER_STATUS_DICT.WAIT_TO_CLOSE]: 'bg-warning',
    [CUSTOMER_STATUS_DICT.CLOSED]: 'bg-dark text-white',
}

