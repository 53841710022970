import {
    GET_PROPERTIES,
    GET_PROPERTIES_SUCCESS,
    CREATE_PROPERTY,
    CANCEL_PROPERTY_ACTION,
    CREATE_PROPERTY_BAD,
    DETAIL_PROPERTY,
    DETAIL_PROPERTY_SUCCESS,
    UPDATE_PROPERTY,
    UPDATE_PROPERTY_BAD,
    UPDATE_PROPERTY_SUCCESS
} from "../../constants/ActionTypes";

export const getProperties = (payload) => {
    return {
        type: GET_PROPERTIES,
        payload: payload
    }
};

export const getPropertiesSuccess = (payload) => {
    return {
        type: GET_PROPERTIES_SUCCESS,
        payload: payload
    }
};

export const createProperty = (payload) => {
    return {
        type: CREATE_PROPERTY,
        payload: payload
    }
};
//
// export const createProjectDeveloperSuccess = (payload) => {
//     return {
//         type: CREATE_PROJECT_DEVELOPERS_SUCCESS,
//         payload: payload
//     }
// };

export const createPropertyBad = (payload) => {
    return {
        type: CREATE_PROPERTY_BAD,
        payload: payload
    }
};
//
export const cancelActionProperty = () => {
    return {
        type: CANCEL_PROPERTY_ACTION
    }
};

export const detailProperty = ({id}) => {
    return {
        type: DETAIL_PROPERTY,
        payload: {id}
    }
};

export const detailPropertySuccess = (payload) => {
    return {
        type: DETAIL_PROPERTY_SUCCESS,
        payload: payload
    }
};

export const updateProperty = (payload) => {
    return {
        type: UPDATE_PROPERTY,
        payload: payload
    }
};

export const updatePropertySuccess = (payload) => {
    return {
        type: UPDATE_PROPERTY_SUCCESS,
        payload: payload
    }
};

export const updatePropertyBad = (payload) => {
    return {
        type: UPDATE_PROPERTY_BAD,
        payload: payload
    }
};





