import {all} from 'redux-saga/effects';
// import mailSagas from './Mail';
// import toDoSagas from './Todo';
// import contactSagas from './Contact';
// import chatSagas from './Chat';
import authSagas from './Auth';
import customerSagas from './Customer';
import projectDeveloperSagas from './ProjectDeveloper';
import propertySagas from './Property';
import addressSagas from './Address';
import propertyIDSagas from './PropertyID';
import imageSagas from './Image';
import propertyItemSagas from './PropertyItem';
import commonSagas from './Common';

export default function* rootSaga(getState) {
  yield all([
    // mailSagas(),
    // toDoSagas(),
    // contactSagas(),
    // chatSagas()
    authSagas(),
    customerSagas(),
    projectDeveloperSagas(),
    propertySagas(),
    addressSagas(),
    propertyIDSagas(),
    imageSagas(),
    propertyItemSagas(),
    commonSagas(),
  ]);
}
