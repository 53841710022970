import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS, GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS,
  HIDE_MESSAGE, ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE, TOGGLE_DROPDOWN_NOTIFICATION
} from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};


export const getNotifications = () => {
  return {
    type: GET_NOTIFICATIONS
  }
};

export const getNotificationsSuccess = (payload) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: payload
  };
};

export const toggleNotificationDropdown = () => {
  return {
    type: TOGGLE_DROPDOWN_NOTIFICATION,
  };
};






