require('dotenv').config()

// local
// const API_URL = 'http://127.0.0.1:8121';
// const PROPERTY_API_URL = 'https://property-id.alpha.houze.io'

// // qa
// const API_URL = 'https://qa.hi-web.houze.app';
// const API_URL = 'https://hi-admin.alpha.houze.io';
// const PROPERTY_API_URL = 'https://property-id.alpha.houze.io'
export const API_URL = process.env.REACT_APP_ENV === 'prod'?'https://hi-web.houze.io':process.env.REACT_APP_ENV === 'beta'?'https://hi-admin.beta.houze.io':'https://hi-admin.alpha.houze.io'
export const PROPERTY_API_URL = process.env.REACT_APP_ENV === 'prod'?'https://property-id.houze.io':process.env.REACT_APP_ENV === 'beta'?'https://property-id.beta.houze.io':'https://property-id.alpha.houze.io'

