import axiosInstance from "../axiosApi";
import {ROUTE_NAME, routeTo, routeToPropertyID} from './routes';

export default {
    getPropertyIDProjects: async (filters) => {
        return await axiosInstance.get(routeToPropertyID(ROUTE_NAME.REMOTE.PROPERTY_ID.PROJECT.PROJECTS, filters)).then(response => {
            return response
        }).catch(error => error)
    },

    detailPropertyIDProject: async ({id}) => {
        return await axiosInstance.get(routeToPropertyID(ROUTE_NAME.REMOTE.PROPERTY_ID.PROJECT.PROJECT_DETAIL, {}, {id})).then(response => {
            return response
        }).catch(error => error)
    },
};
