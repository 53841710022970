import React from 'react';

import NotificationItem from './NotificationItem';
import {notificationDataDisplay} from "./dataDisplay";
import {useSelector} from "react-redux";

const AppNotification = ({notifications, onAppNotificationSelect}) => {
    const {groups} = useSelector(({auth})=> auth)
    const notificationList = notificationDataDisplay(notifications, groups)
    return (
        // <CustomScrollbars className="messages-list scrollbar" style={{display: 'inline-block'}}>
        //
        // </CustomScrollbars>
    <div className={"messages-list scrollbar"}>
        <ul className="list-unstyled">
            {notificationList.map((notification, index) => <NotificationItem key={index} notification={notification} onAppNotificationSelect={onAppNotificationSelect}/>)
            }
        </ul>
    </div>
    )
};

export default AppNotification;

