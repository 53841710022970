import React from 'react';
import {List} from '@material-ui/core';
import NavMenuItem from "./NavMenuItem";
import NavSection from "./NavSection";
import NavCollapse from "./NavCollapse";
import {useSelector} from "react-redux";

const Navigation = props => {
  const {groups} = useSelector(({auth})=> auth)
  const {menuItems} = props;
  return (
    <List component="nav" disablePadding className='side-nav-menu'>
      {
        menuItems.map((item, index) => {
          switch (item.type) {
            case 'section' :
              return item.checkGroup(groups)&&<NavSection {...item} groups={groups} key={index}/>;
            case 'collapse' :
              return item.checkGroup(groups)&&<NavCollapse {...item} key={index}/>;
            case 'item' :
              return item.checkGroup(groups)&&<NavMenuItem {...item} key={index}/>;
          }
        })
      }
    </List>
  )
};

export default Navigation;
