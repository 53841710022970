import {
    GET_PROJECT_DEVELOPERS,
    GET_PROJECT_DEVELOPERS_SUCCESS,
    CREATE_PROJECT_DEVELOPERS,
    CREATE_PROJECT_DEVELOPERS_BAD,
    CREATE_PROJECT_DEVELOPERS_SUCCESS,
    CANCEL_ACTION_PROJECT_DEVELOPERS,
    DETAIL_PROJECT_DEVELOPER,
    DETAIL_PROJECT_DEVELOPER_SUCCESS,
    UPDATE_PROJECT_DEVELOPER,
    UPDATE_PROJECT_DEVELOPER_BAD,
    GET_AUTOCOMPLETE_PROJECT_DEVELOPERS,
    GET_AUTOCOMPLETE_PROJECT_DEVELOPERS_SUCCESS
} from "../../constants/ActionTypes";

export const getProjectDeveloper = (payload) => {
    return {
        type: GET_PROJECT_DEVELOPERS,
        payload: payload
    }
};

export const getProjectDeveloperSuccess = (payload) => {
    return {
        type: GET_PROJECT_DEVELOPERS_SUCCESS,
        payload: payload
    }
};

export const createProjectDeveloper = (payload) => {
    return {
        type: CREATE_PROJECT_DEVELOPERS,
        payload: payload
    }
};
//
// export const createProjectDeveloperSuccess = (payload) => {
//     return {
//         type: CREATE_PROJECT_DEVELOPERS_SUCCESS,
//         payload: payload
//     }
// };

export const createProjectDeveloperBad = (payload) => {
    return {
        type: CREATE_PROJECT_DEVELOPERS_BAD,
        payload: payload
    }
};

export const cancelActionProjectDeveloper = () => {
    return {
        type: CANCEL_ACTION_PROJECT_DEVELOPERS
    }
};

export const detailProjectDeveloper = (payload) => {
    return {
        type: DETAIL_PROJECT_DEVELOPER,
        payload: payload
    }
};

export const detailProjectDeveloperSuccess = (payload) => {
    return {
        type: DETAIL_PROJECT_DEVELOPER_SUCCESS,
        payload: payload
    }
};

export const updateProjectDeveloper = (payload) => {
    return {
        type: UPDATE_PROJECT_DEVELOPER,
        payload: payload
    }
};

export const updateProjectDeveloperBad = (payload) => {
    return {
        type: UPDATE_PROJECT_DEVELOPER_BAD,
        payload: payload
    }
};

export const getAutocompleteProjectDeveloper = (payload) => {
    return {
        type: GET_AUTOCOMPLETE_PROJECT_DEVELOPERS,
        payload: payload
    }
};

export const getAutocompleteProjectDeveloperSuccess = (payload) => {
    return {
        type: GET_AUTOCOMPLETE_PROJECT_DEVELOPERS_SUCCESS,
        payload: payload
    }
};





