export const notifications = [
  {
    id: 1,
    image: "https://via.placeholder.com/208x208",
    name: "Tính năng tin mới",
    time: "",
    message: "Tính năng đang được phát triển",
    badge: 0
  },

];
