import axiosInstance from "../axiosApi";
import { ROUTE_NAME, routeTo } from './routes';
import {objectToFormData} from "../function/objectToFormData";

export default {
    getProjectDevelopers: async (filters) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.PROJECT.DEVELOPERS, filters)).then(response => {
            return response
        }).catch(error => error)
    },
    createProjectDevelopers: async (data) => {
        return await axiosInstance.post(routeTo(ROUTE_NAME.PROJECT.DEVELOPERS_CREATE), data).then(response => {
            return response
        }).catch(error => error)
    },
    updateProjectDeveloper: async ({data, id}) => {
        return await axiosInstance.patch(routeTo(ROUTE_NAME.PROJECT.DEVELOPERS_UPDATE, {}, {id}), objectToFormData(data), {headers: { 'content-type': 'multipart/form-data' }}).then(response => {
            return response
        }).catch(error => error)
    },

    detailProjectDeveloper: async ({id}) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.PROJECT.DEVELOPERS_DETAIL, {}, {id})).then(response => {
            return response
        }).catch(error => error)
    },

    autocomplete: async (filters) => {
        return await axiosInstance.get(routeTo(ROUTE_NAME.PROJECT.AUTOCOMPLETE_PD, filters)).then(response => {
            return response
        }).catch(error => error)
    },
};
