import {
    CANCEL_ITEM_ACTION,
    UPDATE_PROPERTY_ITEM,
    UPDATE_PROPERTY_ITEM_SUCCESS,
    CREATE_PROPERTY_ITEM_SUCCESS,
    CREATE_PROPERTY_ITEM,
    GET_PROPERTY_ITEMS_SUCCESS,
    GET_PROPERTY_ITEMS,
    OPEN_ITEM_UPDATE_DIALOG,
    OPEN_ITEM_CREATE_DIALOG,
    DELETE_PROPERTY_ITEM,
    DELETE_PROPERTY_ITEM_SUCCESS, ITEM_BAD_REQUEST
} from "constants/ActionTypes";

const INIT_STATE = {
    propertyItems: [],
    errors: {},
    propertyItemsLoading: true,
    saving: false,
    openUpdate: false,
    openCreate: false,
    openDelete: false

};


export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_PROPERTY_ITEMS: {
            return {
                ...state,
                propertyItemsLoading: true
            }
        }
        case GET_PROPERTY_ITEMS_SUCCESS: {
            return {
                ...INIT_STATE,
                saving: false,
                propertyItems: action.payload,
                propertyItemsLoading: false
            }
        }
        case CREATE_PROPERTY_ITEM: {
            return {
                ...state,
                saving: true
            }
        }
        case CREATE_PROPERTY_ITEM_SUCCESS: {
            return {
                ...state,
                saving: false,
                openCreate: false
            }
        }
        case UPDATE_PROPERTY_ITEM: {
            return {
                ...state,
                saving: true,
            }
        }
        case UPDATE_PROPERTY_ITEM_SUCCESS: {
            return {
                ...state,
                saving: false,
                openUpdate: false
            }
        }
        case DELETE_PROPERTY_ITEM: {
            return {
                ...state,
                saving: true,
            }
        }
        case DELETE_PROPERTY_ITEM_SUCCESS: {
            return {
                ...state,
                saving: false
            }
        }
        case CANCEL_ITEM_ACTION: {
            return {
                ...state,
                saving: false,
                openCreate: false,
                openUpdate: false,
                openDelete:false,
                errors: {}
            }
        }
        case OPEN_ITEM_UPDATE_DIALOG: {
            return {
                ...state,
                openUpdate: action.payload,
                saving: action.payload?state.saving:action.payload
            }
        }
        case OPEN_ITEM_CREATE_DIALOG: {
            return {
                ...state,
                openCreate: action.payload,
                saving: action.payload?state.saving:action.payload
            }
        }
        case ITEM_BAD_REQUEST: {
            return {
                ...state,
                errors: action.payload,
                saving: false
            }
        }
        default:
            return state;
    }
}
