import {
    TOGGLE_COLLAPSED_NAV,
    WINDOW_WIDTH
} from 'constants/ActionTypes';

const initialSettings = {
    navCollapsed: false,
    width: window.innerWidth
};

const responsive = (state = initialSettings, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                navCollapsed: false
            };
        case WINDOW_WIDTH:
            return {
                ...state,
                width: action.width
            };

        case TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: !state.navCollapsed
            };
        default:
            return state;
    }
};

export default responsive;
